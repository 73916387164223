import { EventEmitter } from 'events';
import { useEffect } from 'react';

export const Events = {
  VendorsOrders: {
    New: 'new',
    Update: 'update'
  },
  Audio: {
    Play: 'audio:play',
    Pause: 'audio:pause'
  }
};

export const eventsManager = new EventEmitter();

export const useEvent = (eventName: string, handler: any, deps: any[]) => {
  useEffect(() => {
    eventsManager.on(eventName, handler);
    return () => {
      eventsManager.off(eventName, handler);
    };
  }, deps);
};
