import apiInstance from '../apis/api';
import { IBranch, IBrand } from '../types/user';

interface GetProfileResponse {
  user: {
    _id: string;
    user: {
      _id: string;
      firstName: string;
      lastName: string;
      name: string;
      phoneVerified: string;
      emailVerified: string;
      lang: string;
      status: boolean;
      deleted: boolean;
      createdAt: string;
      updatedAt: string;
    };
    owner: string | undefined;
    email: string;
    phone: string;
    brandsId: string[];
    branchesId: string[];
    isCallCenter: boolean;
    active: boolean;
    deleted: boolean;
    createdAt: string;
    updatedAt: string;
    branches: IBranch[];
    brands: IBrand[];
    id: string;
  };
}

export function getProfile(): Promise<GetProfileResponse> {
  return apiInstance.get(`/profile`).then((response) => response.data?.data);
}
