import edit2Outline from '@iconify/icons-eva/edit-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import plusCircleOutline from '@iconify/icons-eva/plus-circle-outline';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import { Box, Button, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import TransitionsDialogs from 'pages/menu/Dialog';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { localize } from 'utils/localize';
import * as Yup from 'yup';
import { ItemExtraChoices } from '../../../store/slices/menu';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import EditChoiceForm from '../../../pages/menu/menuManagement/menuItemExtras/EditChoiceForm';

type ChoicesListProps = {
  extraChoicesItems: any;
  formik: ReturnType<typeof useFormik>;
  setValue: (value: ItemExtraChoices[]) => void;
  setFieldUpdate: (value: any) => void;
};

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

const MENU_OPTIONS = ['Edit', 'Delete'];

export default function ChoicesList({
  extraChoicesItems,
  formik,
  setValue,
  setFieldUpdate
}: ChoicesListProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState<null | HTMLDivElement>(null);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [isConfirm, setConfirmOpen] = useState<boolean>(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement>, id: string) => {
    setMenuOpen(event.currentTarget);
    setSelectedRow(id);
  };

  const handleMenuClose = () => {
    setMenuOpen(null);
  };

  const handleUserAction = (option: string) => {
    if (option === 'Delete') {
      handleMenuClose();
      confirmationBoxOpen();
    }
    if (option === 'Edit') {
      handleMenuClose();
      handleUpdateChoiceOpen();
    }
  };

  const confirmationBoxOpen = () => {
    setConfirmOpen(true);
  };

  const confirmationBoxClose = () => {
    setConfirmOpen(false);
  };

  const handleUpdateChoiceOpen = () => {
    setOpen(true);
  };

  const handleUpdateChoiceClose = () => {
    setOpen(false);
  };

  const userConfirmAction = () => {
    const updatedChoices = extraChoicesItems?.filter((c: any) => c._id !== selectedRow);
    setFieldUpdate(updatedChoices);
    confirmationBoxClose();
    setTimeout(() => {
      // enqueueSnackbar('Updated Success', { variant: 'success' });
    }, 1000);
  };

  const { isSubmitting, values, errors, touched, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  return (
    <Stack>
      <TransitionsDialogs
        size="sm"
        open={isConfirm}
        handleClose={confirmationBoxClose}
        handleConfirm={() => {
          userConfirmAction();
        }}
        title={t('app.important')}
      >
        {t('messages.deleteConfirm')}
      </TransitionsDialogs>
      <TransitionsDialogs
        open={open}
        size="sm"
        title={t('menu.updateChoice')}
        handleClose={handleUpdateChoiceClose}
      >
        <EditChoiceForm
          handleClose={handleUpdateChoiceClose}
          currentChoiceItem={extraChoicesItems?.find((c: any) => c._id === selectedRow)}
          setUpdateField={(value: any) => {
            const updatedChoices: any = [...extraChoicesItems];
            const targetChoice = updatedChoices.findIndex((t: any) => t._id === selectedRow);
            updatedChoices[targetChoice] = {
              _id: value._id,
              name: {
                en: value.choiceItemName_en,
                ar: value.choiceItemName_ar
              },
              price: value.choiceItemPrice
            };
            setFieldUpdate(updatedChoices);
          }}
        />
      </TransitionsDialogs>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          {(extraChoicesItems || []).map(({ _id, name, price }: any, i: any) => (
            <Box
              sx={{
                boxShadow: 2,
                borderRadius: 1,
                p: 1,
                my: 2,
                background: '#fff'
              }}
              key={i}
            >
              <Stack
                direction="row"
                spacing={1}
                sx={{ width: '100%', justifyContent: 'space-between' }}
              >
                <Box
                  sx={{
                    cursor: 'pointer',
                    paddingTop: '5px'
                  }}
                  onClick={(e) => handleMenuOpen(e, _id)}
                >
                  <Icon icon={moreVerticalFill} width={20} height={20} />
                </Box>
                <Typography variant="body2" sx={{ flexGrow: 10, my: 0 }}>
                  {localize(name)}
                </Typography>
                <Typography variant="body2" sx={{ my: 0 }}>
                  {price} {t('orders.currency')}
                </Typography>
              </Stack>
              <Menu
                keepMounted
                id="simple-menu"
                anchorEl={isMenuOpen}
                onClose={handleMenuClose}
                open={Boolean(isMenuOpen)}
              >
                {MENU_OPTIONS &&
                  MENU_OPTIONS.map((option) => (
                    <MenuItem key={option} onClick={() => handleUserAction(option)}>
                      <Icon
                        icon={(option === 'delete' && trash2Outline) || edit2Outline}
                        width={20}
                        height={20}
                      />
                      <Typography sx={{ mx: 1 }}>{t(`app.${option.toLowerCase()}`)}</Typography>
                    </MenuItem>
                  ))}
              </Menu>
            </Box>
          ))}
          <FieldArray
            name="newChoices"
            render={(arrayHelpers) => (
              <Box>
                {values?.newChoices.length > 0 &&
                  values?.newChoices.map((_: any, index: number) => (
                    <Box key={index}>
                      <Stack direction={{ xs: 'column', md: 'row' }} sx={{ mb: 1 }} spacing={2}>
                        <TextField
                          {...getFieldProps(`newChoices.${index}.choiceItemName_en`)}
                          fullWidth
                          size="small"
                          label={t('menu.nameEn')}
                          error={Boolean(
                            (touched as any).newChoices?.[index]?.choiceItemName_ar &&
                              (errors as any).newChoices?.[index]?.choiceItemName_ar
                          )}
                          helperText={
                            (touched as any).newChoices?.[index]?.choiceItemName_en &&
                            (errors as any).newChoices?.[index]?.choiceItemName_en
                          }
                        />
                        <TextField
                          {...getFieldProps(`newChoices.${index}.choiceItemName_ar`)}
                          fullWidth
                          size="small"
                          label={t('menu.nameAr')}
                          error={Boolean(
                            (touched as any).newChoices?.[index]?.choiceItemName_ar &&
                              (errors as any)?.newChoices?.[index]?.choiceItemName_ar
                          )}
                          helperText={
                            (touched as any).newChoices?.[index]?.choiceItemName_ar &&
                            (errors as any).newChoices?.[index]?.choiceItemName_ar
                          }
                        />
                        <TextField
                          {...getFieldProps(`newChoices.${index}.choiceItemPrice`)}
                          fullWidth
                          size="small"
                          label={t('menu.price')}
                          sx={{ flexGrow: 2 }}
                          error={Boolean(
                            (touched as any)?.newChoices?.[index]?.choiceItemPrice &&
                              (errors as any).newChoices?.[index]?.choiceItemPrice
                          )}
                          helperText={
                            (touched as any).newChoices?.[index]?.choiceItemPrice &&
                            (errors as any).newChoices?.[index]?.choiceItemPrice
                          }
                        />
                        <Button
                          variant="contained"
                          color="error"
                          sx={{
                            width: '50px',
                            borderRadius: 1,
                            height: '40px',
                            minWidth: 'unset',
                            color: '#fff'
                          }}
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <Typography>-</Typography>
                        </Button>
                      </Stack>
                    </Box>
                  ))}
                <Button
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{ color: '#000000' }}
                  onClick={() =>
                    arrayHelpers.push({
                      choiceItemName_en: '',
                      choiceItemName_ar: '',
                      choiceItemPrice: 0
                    })
                  }
                >
                  <Icon icon={plusCircleOutline} width={20} height={20} />
                  {t('menu.addNewChoice')}
                </Button>
              </Box>
            )}
          />
        </Form>
      </FormikProvider>
    </Stack>
  );
}
