import { Box, Stack, Typography } from '@mui/material';
import { formatDistance, format } from 'date-fns';
import { getOrderIdHash } from 'utils/orders';

interface OrderDetailsHeaderProps {
  id: string;
  orderNo: string;
  createdAt: Date;
}

export default function OrderDetailsHeader({ id, orderNo, createdAt }: OrderDetailsHeaderProps) {
  return (
    <Box>
      <Stack direction="row" sx={{ my: 1 }} justifyContent="space-between">
        <Box>
          <Typography variant="h5">{`Order ${orderNo}`}</Typography>
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            {createdAt && format(new Date(createdAt), 'MM/dd/yyyy')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: 13 }}>
            {createdAt && formatDistance(new Date(createdAt), new Date())}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
}
