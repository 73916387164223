import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { PATH_DASHBOARD } from 'routes/paths';

// Base Components
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Label from 'components/Label';
import Page from 'components/Page';

// Socket
import ReactAudioPlayer from 'react-audio-player';
import { io, Socket } from 'socket.io-client';

// Store
import { last } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  getCurrentOrders,
  getNewOrders,
  Order,
  setNewOrders,
  updateCurrentOrderStatus
} from 'store/slices/orders';
import { dispatch, RootState, useSelector } from 'store/store';
import { baseURL } from '../../../config';
import { useAuthUser } from '../../../hooks/useAuth';
import { localize } from '../../../utils/localize';
import CurrentPendingOrderCard from './CurrentPendingOrderCard';
import TransitionsDialogs from './Dialog';
import NewOrderCard from './NewOrderCard';
import OrderOverview from './OrderOverview';
import SelectRejectionReason from './SelectRejectionReason';
import { getProfile } from '../../../services/auth.service';
import { RefreshTimer } from '../../../components/RefreshTimer';

export type newOrdersType = {
  active: true;
  createdAt: string;
  currentStatus: string;
  customer: {};
  driver: {};
  dropOff: {};
  dropOffZone: {};
  fees: {};
  offers: {}[];
  orderDetails: {
    items: [];
  };
  pickUp: {};
  pickUpZone: {};
  promoCode: string | null;
  status: {}[];
  type: string;
  updatedAt: string;
  _id: string;
};

let socket: Socket; // socket

const orderStates = [
  { id: 0, label: 'PREPARED', color: 'primary' },
  { id: 1, label: 'READY', color: 'secondary' },
  { id: 2, label: 'COMPLETED', color: 'success' }
];

let audioEl: RefObject<HTMLAudioElement> | undefined;

export async function playAudio() {
  if (!audioEl) {
    return;
  }

  pauseAudio();

  new AudioContext().resume();
  audioEl?.current?.load();
  await audioEl?.current?.play();
}

export function pauseAudio() {
  if (!audioEl) {
    return;
  }

  audioEl?.current?.pause();
}

export default function OrdersPipeline() {
  const audioRef = useRef<ReactAudioPlayer | null>(null);
  const { t } = useTranslation();
  const { currentBranch } = useAuthUser();

  const { data } = useQuery('profile', getProfile);
  const profile = data;

  useEffect(() => {
    socket = io(`${baseURL.socketEndpoint}`, {
      reconnection: true,
      reconnectionDelay: 500,
      transports: ['websocket']
    });
    socket?.on('connect', () => {});
    socket?.emit('join-room', { room: currentBranch, clientData: {} });

    return () => {
      socket.disconnect();
    };
  }, [currentBranch]);

  // const [orderPreviews, setOrderPreviews] = useState<Order[]>([]);
  const [cancelledOrders, setCancelledOrders] = useState<Order[]>([]);
  const [currentOrderPreview, setCurrentOrderPreview] = useState<Order | null>(null);
  const [isDisabled, setIsDisabled] = useState<string[]>([]);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const { newOrders, currentOrders, isLoading, isLoadingNewOrders } = useSelector(
    (state: RootState) => state.orders
  );

  const handleNewOrderClose = () => {
    audioRef.current?.audioEl?.current?.pause();
  };

  const handleCancelledOrdersClose = () => {
    const nCancelled = [...cancelledOrders];
    nCancelled.pop();
    setCancelledOrders(nCancelled);
  };

  useEffect(() => {
    dispatch(getNewOrders({ isCallCenter: profile?.user?.isCallCenter }));
    dispatch(getCurrentOrders());
    // dispatch(getUpcomingOrders());
  }, [profile]);

  useEffect(() => {
    document.body.addEventListener('mouseup', pauseAudio);
    return () => {
      document.body.removeEventListener('mouseup', pauseAudio);
    };
  }, []);

  const onNewVendorOrder = async (data: any) => {
    const order = data?.order;
    const branchId = order?.orderDetails?.branch?._id;

    if (profile?.user?.branches?.find((branch) => branch._id === branchId)) {
      dispatch(getNewOrders({ withSound: true, isCallCenter: profile?.user?.isCallCenter }));
      // dispatch(setNewOrders(order));
      // setOrderPreviews((prev) => [...prev, order]);

      new AudioContext().resume();
      audioRef.current?.audioEl?.current?.pause();
      audioRef.current?.audioEl?.current?.load();
      await audioRef.current?.audioEl?.current?.play();
      if (window.Notification) {
        // eslint-disable-next-line no-new
        const notification = new window.Notification(`#${order.orderNo}`, {
          icon: 'favicon.ico',
          body: `New pending order is waiting your magical touch`
        });

        notification.onclick = () => {
          window.focus();
        };
      }
    }
  };

  const onOrderUpdated = (data: any) => {
    dispatch(getNewOrders({ isCallCenter: profile?.user?.isCallCenter }));
    dispatch(getCurrentOrders());

    const order = data?.order;
    const status: string = order?.currentStatus || '';
    const cancelled = ['CANCELLED_BY_CUSTOMER', 'CANCELLED_BY_OPERATOR'].includes(status);
    const branchId = order?.orderDetails?.branch?._id;

    if (profile?.user?.branches?.find((branch) => branch._id === branchId) == null) {
      return;
    }

    if (cancelled) {
      // const index = orderPreviews.findIndex((o) => o._id === data.order?._id);
      // if (index !== -1) {
      //   const nOrderPreviews = [...orderPreviews];
      //   nOrderPreviews.splice(index, 1);
      //   setOrderPreviews(nOrderPreviews);
      // }

      if (currentOrderPreview?._id === data.order?._id) {
        setCurrentOrderPreview(null);
      }

      setCancelledOrders((prev) => {
        prev.push(data.order);
        return prev;
      });
    }
  };

  useEffect(() => {
    if (profile) {
      profile.user.branches.forEach((branch) => {
        socket.on(branch._id, onNewVendorOrder);
        if (profile.user.isCallCenter) {
          socket.on(`vendor-order-updated:${branch._id}`, onOrderUpdated);
        }
      });
    }
    // socket.on('order-updated', onOrderUpdated);

    return () => {
      if (profile) {
        profile.user.branches.forEach((branch) => {
          socket.off(branch._id, onNewVendorOrder);
          socket.off(`vendor-order-updated:${branch._id}`, onOrderUpdated);
        });
      }
      // socket.off('order-updated', onOrderUpdated);
    };
  }, [currentOrderPreview, profile]);

  // const addToCurrentOrder = (item: any) => {
  //   handleNewOrderClose();

  //   const nOrderPreviews = [...orderPreviews];
  //   const order = nOrderPreviews.pop() || null;

  //   setOrderPreviews(nOrderPreviews);
  //   setCurrentOrderPreview(order);
  // };

  const setOrderToReadyState = (orderId: any) => {
    socket.emit('VENDOR_ORDER_READY', { room: currentBranch });
    setIsDisabled([...isDisabled, orderId]);
    dispatch(updateCurrentOrderStatus(orderId, 'READY'));
  };

  const handleRejectionReason = (reasonId?: string) => {
    if (!reasonId) {
      return console.error(`Reason id is missing`);
    }

    socket.emit('VENDOR_ORDER_REJECT', { room: currentBranch });
    closeCancelationDialog();
    closeNewOrderDetailsDialog();

    if (currentOrderPreview != null) {
      dispatch(updateCurrentOrderStatus(currentOrderPreview._id, 'REJECTED_BY_BRANCH', reasonId));
    }

    return null;
  };

  const acceptNewOrder = (orderId: any) => {
    socket.emit('VENDOR_ORDER_ACCEPTED', { room: currentBranch });
    closeNewOrderDetailsDialog();
    dispatch(updateCurrentOrderStatus(orderId, 'IN_PROGRESS'));
  };

  const handleOpenDialogForOrderDetails = (id: string, order: any) => {
    // pauseAudio();
    setCurrentOrderPreview(order);
  };

  const closeNewOrderDetailsDialog = () => {
    setCurrentOrderPreview(null);
  };

  const openCancelationDialog = () => {
    setIsOpenDialog(true);
  };

  const closeCancelationDialog = () => {
    setIsOpenDialog(false);
  };

  return (
    <Page title="Menu Management | Haader-APP">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('navitems.ordersOverview')}
          links={[{ name: '', href: PATH_DASHBOARD.root }]}
        />
        <div
          style={{
            position: 'relative',
            background: '#fff',
            zIndex: -10000000,
            width: '100%',
            padding: '10px',
            display: 'none'
          }}
        >
          <ReactAudioPlayer
            style={{ position: 'absolute', background: '#fff', zIndex: -100000 }}
            src="/sound/sound.mp3"
            ref={(ref) => {
              audioRef.current = ref;
              audioEl = ref?.audioEl;
            }}
            autoPlay={false}
            loop={true}
            controls
          />
        </div>

        {/* <TransitionsDialogs
          open={orderPreviews.length > 0}
          size="sm"
          title=""
          handleClose={handleNewOrderClose}
        >
          <Box
            display="flex"
            flexDirection="row-reverse"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="button">
              {localize(last(orderPreviews)?.orderDetails?.brand?.name)}
              {' / '}
              {localize(last(orderPreviews)?.orderDetails?.branch?.name)} (
              {last(orderPreviews)?.orderNo})
            </Typography>
            <Button variant="contained" color="primary" onClick={() => addToCurrentOrder([])}>
              {t('orders.orderPreview')}
            </Button>
          </Box>
        </TransitionsDialogs> */}

        <TransitionsDialogs
          open={cancelledOrders.length > 0}
          size="sm"
          title=""
          handleClose={handleCancelledOrdersClose}
        >
          <Typography sx={{ fontWeight: 'bold', fontSize: '18px', color: 'red' }}>
            {t('orders.cancelledOrder', { orderNo: last(cancelledOrders)?.orderNo })}
          </Typography>
        </TransitionsDialogs>

        <TransitionsDialogs
          open={currentOrderPreview != null}
          size="xs"
          title=""
          handleClose={closeNewOrderDetailsDialog}
        >
          {currentOrderPreview != null ? (
            <OrderOverview
              orderDetails={currentOrderPreview || {}}
              orderId={currentOrderPreview?._id!}
              closeDialog={closeNewOrderDetailsDialog}
              acceptNewOrder={(id) => acceptNewOrder(id)}
              rejectNewOrder={openCancelationDialog}
            />
          ) : null}
        </TransitionsDialogs>

        <TransitionsDialogs
          open={isOpenDialog}
          size="xs"
          title="Choose a rejection reason"
          handleClose={closeCancelationDialog}
        >
          <SelectRejectionReason
            handleClose={closeCancelationDialog}
            handleRejection={handleRejectionReason}
          />
        </TransitionsDialogs>

        {/* {countdown} */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box display="flex" gap={4} sx={{ pb: 2 }}>
              <Typography variant="h6">
                {t('orders.newOrders')} <Label color="warning"> {newOrders.length} </Label>
              </Typography>
              <RefreshTimer
                loading={isLoadingNewOrders}
                onRefresh={() =>
                  dispatch(
                    getNewOrders({ withSound: true, isCallCenter: profile?.user?.isCallCenter })
                  )
                }
              />
            </Box>
            {newOrders?.map((order: any) => (
              <NewOrderCard
                key={order._id}
                id={order._id}
                order={order}
                openNewOrderDetailsDialog={() => handleOpenDialogForOrderDetails(order._id, order)}
              />
            ))}
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{ pb: 2 }}>
              {t('orders.currentOrders')} <Label color="warning"> {currentOrders.length} </Label>
            </Typography>
            {currentOrders?.map(({ _id: id, ...order }) => (
              <CurrentPendingOrderCard
                key={id}
                id={id}
                currentOrdersDetails={order}
                setOrderToReadyState={(id) => setOrderToReadyState(id)}
                isDisabled={isDisabled.includes(id)}
              />
            ))}
          </Grid>
        </Grid>
        {/* <Button variant="contained" color="primary" onClick={rejecto}>
          {' '}
          accept{' '}
        </Button>
        <Button variant="contained" color="primary" onClick={sssssok}>
          {' '}
          send order{' '}
        </Button>
        <Button variant="contained" color="primary" onClick={cANCEL}>
          {' '}
          reject{' '}
        </Button> */}
      </Container>
    </Page>
  );
}
