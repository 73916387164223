import { Box, IconButton, createStyles, makeStyles } from '@material-ui/core';
import { Cached } from '@material-ui/icons';
import { Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { setInterval } from 'timers';

interface RefreshTimerProps {
  loading: boolean;
  onRefresh: () => void;
}

const ONE_SECOND_MS = 1000;
const REFRESH_AFTER_SECONDS = 40;

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: '$spin 0.4s linear infinite'
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)'
      },
      '100%': {
        transform: 'rotate(360deg)'
      }
    }
  })
);

export const RefreshTimer = ({ loading, onRefresh }: RefreshTimerProps) => {
  const classes = useStyles();
  const timerId = useRef<NodeJS.Timer | null>(null);
  const loadingRef = useRef(loading);
  const [seconds, setSeconds] = useState(REFRESH_AFTER_SECONDS);

  const onClick = useCallback(() => {
    if (loading) {
      return;
    }

    onRefresh();
  }, [loading]);

  // Seconds timer
  useEffect(() => {
    timerId.current = setInterval(() => {
      setSeconds((prev) => Math.max(0, prev - 1));
    }, ONE_SECOND_MS);

    return () => {
      clearInterval(timerId.current!);
    };
  }, []);

  // Refetch when seconds are depleted
  useEffect(() => {
    if (seconds === 0) {
      onRefresh();
    }
  }, [seconds]);

  // Reset seconds if loading was true then turned to false
  // Update loading ref for next effect
  useEffect(() => {
    if (loading === false && loadingRef.current === true) {
      setSeconds(REFRESH_AFTER_SECONDS);
    }
    loadingRef.current = loading;
  }, [loading]);

  return (
    <Box display="flex" flexDirection="row-reverse" alignItems="center">
      <IconButton
        className={loading ? classes.rotateIcon : undefined}
        size="small"
        onClick={onClick}
      >
        <Cached />
      </IconButton>
      <Box width={24} height={24}>
        {seconds !== 0 ? <Typography>{seconds}</Typography> : null}
      </Box>
    </Box>
  );
};
