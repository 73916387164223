import { Box, Button, Stack, Typography } from '@mui/material';
import { useField } from 'formik';

type CounterProps = {
  startValue?: string;
  minBaseValue?: number;
  maxBase?: number;
  minBase?: number;
  name: string;
  setFieldVal?: (val: number) => void;
};

export default function Counter({
  startValue,
  name,
  minBaseValue,
  maxBase,
  minBase,
  setFieldVal
}: CounterProps) {
  const [field, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;
  const baseVal = minBaseValue || 0;

  const increment = () => (maxBase && value + 1 > maxBase ? setValue(value) : setValue(value + 1));
  const decrement = () => {
    if (minBase && minBase > value - 1 && value > baseVal) {
      value > baseVal && setValue(value - 1);
      setFieldVal && setFieldVal(value - 1);
    }
    if (value > baseVal) {
      setValue(value - 1);
    }
  };

  return (
    <Box sx={{ boxShadow: 2, borderRadius: 1 }}>
      <Stack direction="row" spacing={{ xs: 1 }}>
        <Button sx={{ color: 'black' }} onClick={decrement}>-</Button>
        <Typography variant="h6" sx={{ mt: 2 }}>
          {value}
        </Typography>
        <Button sx={{ color: 'black' }} onClick={increment}>+</Button>
      </Stack>
    </Box>
  );
}
