import { useState, useEffect } from 'react';

export default function useCountdown(period: number) {
  const [state, setState] = useState({ time: { m: 0, s: 0 }, seconds: period * 60 });
  const [timer, setTimer] = useState(0);

  const secondsToTime = (secs: number) => {
    const divisor_for_minutes = secs % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);
    const seconds = Math.ceil(divisor_for_minutes % 60);

    const timeFormat = {
      m: minutes,
      s: seconds
    };

    return timeFormat;
  };

  useEffect(() => {
    const timeLeftVar = secondsToTime(period * 60);
    setState({ ...state, time: timeLeftVar });
  }, []);

  useEffect(() => {
    const countDown = () => {
      const seconds = state.seconds - 1;
      setState({
        time: secondsToTime(seconds),
        seconds
      });

      // Check if we're at zero.
      if (seconds === 0) {
        clearInterval(timer);
      }
    };

    let interval: any = null;

    if (state.time.m > 0) {
      interval = setInterval(countDown, 1000);
    }
    return () => clearInterval(interval);
  }, [state]);

  const time = `${state.time.m} : ${state.time.s}`;

  return time;
}
