import { Typography, Card, Stack } from '@mui/material';
import { formatDistance } from 'date-fns';
import { getOrderIdHash } from 'utils/orders';
import { localize } from '../../../utils/localize';

interface NewOrderCardProps {
  order: any;
  openNewOrderDetailsDialog: () => void;
  id: string;
}

export default function NewOrderCard({ order, openNewOrderDetailsDialog, id }: NewOrderCardProps) {
  const { createdAt, orderNo } = order;

  return (
    <Card
      sx={{ p: 2, mb: 2, backgroundColor: '#F3E804', borderRadius: 1 }}
      onClick={openNewOrderDetailsDialog}
    >
      <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          {localize(order?.orderDetails?.brand?.name)}
          {' / '}
          {localize(order?.orderDetails?.branch?.name)} ({order?.orderNo})
        </Typography>
        {createdAt ? (
          <Typography variant="body2">{formatDistance(new Date(createdAt), new Date())}</Typography>
        ) : null}
      </Stack>
    </Card>
  );
}
