import { Box, Card, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RootState, useSelector } from '../../../store/store';
import { localize } from '../../../utils/localize';

function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
  return { name, calories, fat, carbs, protein };
}

const BASIC_TABLE = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3)
];

export default function OrderItemsDetails({ order }: any) {
  // const [total, setTotalFees] = useState<any>();
  const { ordersList } = useSelector((state: RootState) => state.orders);
  const { t } = useTranslation();

  return (
    <Paper elevation={3}>
      <Box
        sx={{
          boxShadow: 'none !important',
          backgroundColor: '#e1eced',
          color: '#637381',
          p: 2,
          mb: 1
        }}
      >
        {t('orders.orderDetails')}
      </Box>
      {order?.orderDetails?.items?.map((row: any, i: any) => (
        <Card sx={{ mb: 1, borderRadius: 0, paddingBottom: '5px' }} key={i}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
              paddingLeft: '30px',
              paddingRight: '13px',
              paddingTop: '5px'
            }}
          >
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px', mb: '8px' }}>
              <Typography sx={{ fontSize: '17px', color: '#01645c', fontWeight: 'bold' }}>
                {t('orders.quantity')}
                {row.quantity}
              </Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                {localize(row.name)}
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ fontSize: '16px' }}>
              {row.price}
              {t('orders.currency')}
            </Typography>
          </Stack>

          <Stack direction="row" paddingLeft="53px">
            <Typography color="GrayText" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
              {localize(row.description)}
            </Typography>
          </Stack>
          {row?.extras?.map((extra: any, i: any) => (
            <Stack
              key={i}
              sx={{
                paddingLeft: '65px',
                paddingRight: '13px'
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap',
                  paddingLeft: '10px',
                  paddingTop: '5px'
                }}
              >
                <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                  {localize(extra?.name)}
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '16px' }}>
                  {extra?.choices?.reduce(
                    (acc: number, current: any) => (acc += current.price || 0),
                    0
                  )}
                  {t('orders.currency')}
                </Typography>
              </Stack>
              {extra?.choices?.map((choice: any) => (
                <Box
                  key={choice._id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    boxShadow: 'unset',
                    flexWrap: 'nowrap',
                    paddingLeft: '25px',
                    paddingRight: '13px'
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: '14px' }}>
                    {localize(choice.name)}
                  </Typography>
                </Box>
              ))}
            </Stack>
          ))}
          {row?.specialRequest && (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                paddingLeft: '65px',
                paddingRight: '13px'
              }}
            >
              <Typography sx={{ fontSize: '14px', color: '#01645c', fontWeight: 'bold' }}>
                {t('orders.notes')}
              </Typography>
              <Typography sx={{ fontSize: '13px' }}>{row.specialRequest}</Typography>
            </Box>
          )}
        </Card>
      ))}
      {order?.orderDetails?.notes && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            paddingLeft: '30px',
            paddingRight: '13px',
            mb: '8px'
          }}
        >
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>{t('orders.notes')}</Typography>
          <Typography sx={{ fontSize: '14px' }}>{order?.orderDetails?.notes}</Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          paddingLeft: '30px',
          paddingRight: '13px',
          mb: '8px',
          paddingBottom: '5px'
        }}
      >
        <Typography variant="h6" sx={{ color: '#01645c' }}>
          {t('orders.promoCode')}
        </Typography>
        <Typography align="right" sx={{ color: 'red', fontSize: '18px' }}>
          {'- '}
          {order?.fees?.discountType === 'ORDER' ? order?.fees?.discount.toFixed(2) : 0}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          paddingLeft: '30px',
          paddingRight: '13px',
          mb: '70px',
          paddingBottom: '5px'
        }}
      >
        <Typography variant="h6" sx={{ color: '#01645c' }}>
          {t('orders.total')}
        </Typography>
        <Typography align="right" sx={{ color: '#01645c', fontSize: '18px' }}>
          {order?.fees?.discountType === 'ORDER'
            ? (order?.fees?.orderFees - order?.fees?.discount).toFixed(2)
            : order?.fees?.orderFees.toFixed(2)}
        </Typography>
      </Box>
    </Paper>
  );
}
