import { CreditCard } from '@material-ui/icons';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function ContactsDetails({ orderDetails, target }: any) {
  const { t } = useTranslation();

  return (
    <Box>
      <Stack direction="row" sx={{ my: 1 }}>
        <Stack direction="row" sx={{ my: 1, flex: 1 }}>
          <IconButton aria-label="close" sx={{ border: '1px solid #eee' }}>
            <PersonOutlineIcon />
          </IconButton>
          <Stack sx={{ mx: 2 }}>
            <Typography variant="body2" sx={{ fontSize: 12, color: '#01645c', fontWeight: 'bold' }}>
              {t('orders.customer')}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: 13 }}>
              {`${orderDetails?.customer?.user?.name}` ||
                `${orderDetails?.customer?.user?.firstName} ${orderDetails?.customer?.user?.lastName}`}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" sx={{ my: 1, flex: 1 }}>
          <IconButton aria-label="close" sx={{ border: '1px solid #eee' }}>
            <CreditCard />
          </IconButton>
          <Stack sx={{ mx: 2 }}>
            <Typography variant="body2" sx={{ fontSize: 12, color: '#01645c', fontWeight: 'bold' }}>
              {t('orders.paymentMethod')}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: 12 }}>
              {orderDetails?.fees?.paymentMethod}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {/* {orderDetails?.driver ? (
        <Stack direction="row" sx={{ my: 1 }}>
          <Stack direction="row" sx={{ my: 1, flex: 1 }}>
            <IconButton aria-label="close" sx={{ width: 42, height: 42, border: '1px solid #eee' }}>
              <DirectionsBikeIcon />
            </IconButton>
            <Stack sx={{ mx: 2 }}>
              <Typography
                variant="body2"
                sx={{ fontSize: 12, color: '#01645c', fontWeight: 'bold' }}
              >
                {t('orders.driver')}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: 13 }}>
                {`${orderDetails?.driver?.user?.name}` ||
                  `${orderDetails?.driver?.user?.firstName} ${orderDetails?.driver?.user?.lastName}`}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" sx={{ my: 1, flex: 1 }}>
            <IconButton aria-label="close" sx={{ border: '1px solid #eee' }}>
              <PhoneIcon />
            </IconButton>
            <Stack sx={{ mx: 2 }} justifyContent="center" alignItems="center">
              <Typography variant="body2" sx={{ fontSize: 12 }}>
                {orderDetails?.driver?.phone}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      ) : null} */}
    </Box>
  );
}
