import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Stack, Paper, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { useTranslation } from 'react-i18next';

interface InitialState {
  choiceItemName_en: string;
  choiceItemName_ar: string;
  choiceItemPrice: number;
}

export default function EditChoiceForm({ handleClose, currentChoiceItem, setUpdateField }: any) {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const UpdateSectionSchema = Yup.object().shape({
    choiceItemName_en: Yup.string().required(t('menu.nameEnValidate')),
    choiceItemName_ar: Yup.string().required(t('menu.nameArValidate')),
    choiceItemPrice: Yup.number().required(t('menu.priceValidate'))
  });

  const formik = useFormik<InitialState>({
    enableReinitialize: true,
    initialValues: {
      choiceItemName_en: currentChoiceItem?.name?.en || '',
      choiceItemName_ar: currentChoiceItem?.name.ar || '',
      choiceItemPrice: currentChoiceItem?.price || 0
    },

    validationSchema: UpdateSectionSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      const { choiceItemName_en, choiceItemName_ar, choiceItemPrice } = values;
      try {
        setUpdateField({
          _id: currentChoiceItem._id,
          choiceItemName_en,
          choiceItemName_ar,
          choiceItemPrice
        });
        // enqueueSnackbar('Update success', { variant: 'success' });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        resetForm();
        handleClose();
      } catch (error: any) {
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      }
    }
  });

  const { isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <Stack>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Paper
            sx={{
              p: 3,
              my: 2,
              bgcolor: 'background.neutral'
            }}
          >
            <Stack direction={{ xs: 'column', md: 'row' }} sx={{ mb: 1 }} spacing={2}>
              <TextField
                {...getFieldProps(`choiceItemName_en`)}
                fullWidth
                size="small"
                label={t('menu.nameEn')}
              />
              <TextField
                {...getFieldProps(`choiceItemName_ar`)}
                fullWidth
                size="small"
                label={t('menu.nameAr')}
              />
              <TextField
                {...getFieldProps(`choiceItemPrice`)}
                fullWidth
                size="small"
                label={t('menu.price')}
                sx={{ flexGrow: 2 }}
              />
            </Stack>
          </Paper>
          <Stack>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              {t('app.saveChanges')}
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </Stack>
  );
}
