export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;
export const baseDomain = {
  apiEndpoint: process.env.REACT_APP_BASE_URL
};
export const baseURL = {
  apiEndpoint: process.env.REACT_APP_BASE_URL_API_END_POINT,
  socketEndpoint: process.env.REACT_APP_SOKCET_ENDPOINT,
  uploadEndpoint: process.env.REACT_APP_UPLOAD_ENDPOINT
};

export const TestBaseURL = {
  apiEndpoint: process.env.REACT_APP_BASE_URL_API_END_POINT_TEST
};

export const customerBaseURL = {
  apiEndpoint: process.env.REACT_APP_BASE_URL_API__CUSTOMER_END_POINT
};

export const sentry = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
};
