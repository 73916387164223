import { forwardRef } from 'react';
import { Slide, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children?: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

interface propDialog {
  open: boolean;
  size: any;
  handleClose?: () => void;
  handleConfirm?: () => void;
  title: string;
  children: React.ReactNode;
}

export default function TransitionsDialogs({
  open,
  size = 'sm',
  handleClose,
  handleConfirm,
  title,
  children
}: propDialog) {
  return (
    <Dialog
      // eslint-disable-next-line no-nested-ternary
      maxWidth={size === 'sm' ? 'sm' : size === 'md' ? 'md' : 'xs'}
      open={open}
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      {handleConfirm && (
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            cancel
          </Button>
          <Button variant="contained" onClick={handleConfirm}>
            confirm
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
