import { baseURL } from '../config';

export class SocketSharedWorker {
  static worker: SharedWorker;

  static refs: Record<string, any> = {};

  static initialize() {
    this.worker = new SharedWorker('/socket-shared-worker.js');

    this.worker.port.start();

    this.worker.port.postMessage({
      function: 'initialize',
      args: [baseURL.socketEndpoint]
    });

    this.worker.port.onmessage = (event) => {
      console.log('message from worker >', event);
      console.log(this.refs);

      const { ref } = event.data;
      if (ref in this.refs) {
        this.refs[ref](event.data.payload);
      }
    };
  }

  static emit(event: string, payload: any) {
    this.worker.port.postMessage({
      function: 'sendMessage',
      args: [event, payload]
    });
  }

  static once(event: string, listener: any) {
    this.refs[event] = listener;

    this.worker.port.postMessage({
      function: 'once',
      args: [event]
    });
  }

  static on(event: string, listener: any) {
    this.refs[event] = listener;

    this.worker.port.postMessage({
      function: 'on',
      args: [event]
    });
  }

  static off(event: string) {
    delete this.refs[event];

    this.worker.port.postMessage({
      function: 'off',
      args: [event]
    });
  }
}
