import { PropsWithChildren, useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Events, eventsManager } from '../hooks/useEvent';
import { getProfile } from '../services/auth.service';
import { SocketSharedWorker } from '../utils/shared-worker';

export function LiveOrdersHOC({ children }: PropsWithChildren<any>) {
  const { data } = useQuery('profile', getProfile);
  const profile = data;

  const onNewVendorOrder = useCallback(async (data: any) => {
    eventsManager.emit(Events.VendorsOrders.New, data);
  }, []);

  const onOrderUpdated = useCallback((data: any) => {
    const updates = data?.updates;
    const currentStatus = data?.order?.currentStatus;

    if (updates === 'status' && currentStatus === 'ACCEPTED') {
      eventsManager.emit(Events.VendorsOrders.New, data);
    } else {
      eventsManager.emit(Events.VendorsOrders.Update, data);
    }
  }, []);

  useEffect(() => {
    // if (profile) {
    //   profile.user.branches.forEach((branch) => {
    //     SocketSharedWorker.on(branch._id, onNewVendorOrder);
    //     // @ts-ignore
    //     branch.vendorsId.forEach((vendorId) => {
    //       SocketSharedWorker.on(vendorId, onNewVendorOrder);
    //     });
    //   });
    // }
    SocketSharedWorker.on('order-updated', onOrderUpdated);

    return () => {
      if (profile) {
        profile.user.branches.forEach((branch) => {
          SocketSharedWorker.off(branch._id);
        });
      }
      SocketSharedWorker.off('order-updated');
    };
  }, [profile]);

  return children;
}
