import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

// import RoleBasedGuard from '../guards/RoleBasedGuard';
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import { PATH_DASHBOARD } from './paths';
import { ItemsList } from '../refactored/pages/BrandMenu/ItemsList';
import ItemForm from '../refactored/pages/BrandMenu/ItemForm';

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
      ]
    },

    { path: '/', element: <Navigate to="/auth/login" replace /> },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'app', element: <DashboardStats /> },
        // { path: 'menu', element: <MenuView /> },
        // { path: 'menu/view', element: <MenuManagement /> },
        { path: PATH_DASHBOARD.general.users, element: <Users /> },
        { path: PATH_DASHBOARD.general.brands, element: <Brands /> },
        { path: PATH_DASHBOARD.general.menu, element: <BrandMenu /> },
        {
          path: PATH_DASHBOARD.general.section,
          element: (
            <BrandMenu>
              <ItemsList />
            </BrandMenu>
          )
        },
        {
          path: PATH_DASHBOARD.general.createItem,
          element: (
            <BrandMenu>
              <ItemForm create edit />
            </BrandMenu>
          )
        },
        {
          path: PATH_DASHBOARD.general.editItem,
          element: (
            <BrandMenu>
              <ItemForm edit />
            </BrandMenu>
          )
        },
        {
          path: PATH_DASHBOARD.general.item,
          element: (
            <BrandMenu>
              <ItemForm />
            </BrandMenu>
          )
        },
        {
          path: PATH_DASHBOARD.general.itemAvailability,
          element: <ItemAvailability />
        },
        // { path: 'menu/check', element: <MenuAvailability /> },
        // { path: 'menu/view/:sectionId/:itemId', element: <EditItem /> },
        // { path: 'menu/view/:sectionId/item', element: <EditItem /> },
        // { path: 'item/:id', element: <CreateNewItem /> },
        { path: 'orders-overview', element: <OrdersOverView /> },
        { path: 'branch-status', element: <BranchStatus /> },
        { path: 'order-status', element: <OrdersPipeline /> },
        { path: 'orders-history', element: <OrdersList /> },
        // { path: 'performance', element: <Performance /> },
        { path: 'reports', element: <Reports /> },
        { path: 'working-hours', element: <WorkingHours /> },
        {
          path: 'user',
          children: [
            { path: 'profile', element: <Navigate to="/dashboard/user/profile" replace /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// auth
const Login = Loadable(lazy(() => import('../pages/auth/login')));
// Restaurant
const Users = Loadable(lazy(() => import('../refactored/pages/users/index')));
const Brands = Loadable(lazy(() => import('../refactored/pages/Brands')));
const MenuView = Loadable(lazy(() => import('../pages/menu')));
const BrandMenu = Loadable(lazy(() => import('../refactored/pages/BrandMenu')));
const ItemAvailability = Loadable(lazy(() => import('../pages/itemAvailability')));
const CreateNewItem = Loadable(lazy(() => import('../pages/menu/CreateNewItem')));
const EditItem = Loadable(lazy(() => import('../pages/menu/menuManagement')));
const OrdersList = Loadable(lazy(() => import('../pages/orders/ordersList')));
const OrdersOverView = Loadable(lazy(() => import('../pages/orders/orders')));
const Performance = Loadable(lazy(() => import('../pages/performance')));
const OrdersPipeline = Loadable(lazy(() => import('../pages/orders/orders')));
const Reports = Loadable(lazy(() => import('../pages/reports')));
const WorkingHours = Loadable(lazy(() => import('../pages/workingHours')));
const BranchStatus = Loadable(lazy(() => import('../pages/branchStatus')));
const DashboardStats = Loadable(lazy(() => import('../pages/dashboardStats')));
// Errors
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
