import { Box, BoxProps } from '@mui/material';

export default function SectionsBadgeIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg width="27" height="36" xmlns="http://www.w3.org/2000/svg">
        <g fillRule="nonzero" fill="none">
          <path
            d="M27 27.565v5.808c0 .931-.48 1.771-1.29 2.252-.405.255-.87.375-1.335.375-.435 0-.855-.105-1.26-.315v-.015l-9.435-5.148h-.36L3.885 35.67c-.825.45-1.8.435-2.595-.045A2.596 2.596 0 0 1 0 33.373v-5.808l12.72-7.715a1.53 1.53 0 0 1 1.56 0L27 27.565z"
            fill="#01645C"
          />
          <path
            d="M27 2.57V27l-12.72-7.55c-.48-.28-1.08-.28-1.56 0L0 27V2.57C0 1.16 1.185 0 2.625 0h21.75C25.815 0 27 1.16 27 2.57z"
            fill="#FCED11"
          />
          <path
            d="M13.09 19.636c-.26 0-.523.072-.755.215L0 27.567v5.81c0 .93.465 1.77 1.25 2.25.4.248.843.373 1.291.373.419 0 .84-.108 1.226-.326l9.15-5.149h.174V19.636z"
            fill="#219C9B"
          />
          <path
            d="M13.09 0H2.546C1.15 0 0 1.16 0 2.57V27l12.335-7.55c.232-.14.494-.21.756-.21V0z"
            fill="#FCED11"
          />
          <path
            d="M18.79 12.344a.57.57 0 0 1-.486.406l-3.07.29-1.214 2.966a.565.565 0 0 1-.52.358.565.565 0 0 1-.52-.359l-1.214-2.964-3.07-.291a.57.57 0 0 1-.486-.406.606.606 0 0 1 .165-.626l2.32-2.124-.684-3.145a.603.603 0 0 1 .22-.61.549.549 0 0 1 .62-.028L13.5 7.463l2.647-1.652a.544.544 0 0 1 .622.029c.184.139.27.378.22.609l-.685 3.145 2.32 2.123c.171.156.236.402.166.627z"
            fill="#01645C"
          />
        </g>
      </svg>
    </Box>
  );
}
