import { Box } from '@material-ui/core';
import { get } from 'lodash';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DropResult,
  Droppable,
  ResponderProvided
} from 'react-beautiful-dnd';
import { MongoID } from '../../types/menu.types';

export interface DraggableListRenderItemProps<T extends MongoID, U = unknown> {
  index: number;
  item: T;
  itemProps: U;
  provided: DraggableProvided;
}

export interface DraggableListProps<T extends MongoID, U> {
  list: T[];
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
  renderItem: (props: DraggableListRenderItemProps<T, U>) => JSX.Element;
  itemProps: U;
  idField?: string;
}

export function DraggableList<T, U>(props: DraggableListProps<T & MongoID, U>) {
  const { list, idField, renderItem, onDragEnd, itemProps } = props;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <Box {...provided.droppableProps} ref={provided.innerRef}>
            {list?.map((item, index) => (
              <Draggable
                key={item._id}
                draggableId={get(item, idField || '') || get(item, '_id').toString()}
                index={index}
              >
                {(provided) => renderItem({ item, index, provided, itemProps })}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
}
