import { QueryFunctionContext } from 'react-query';
import apiInstance from '../apis/api';
import { Section } from '../types/menu.types';

interface GetBrandSectionsResponse {
  itemCategories: Section[];
}

export function addSection(data: { brandId: string; sectionItem: any }) {
  const { brandId, sectionItem } = data;
  return apiInstance
    .post(`itemCategories/`, {
      brandId,
      ...sectionItem
    })
    .then((response) => response.data);
}

export function deleteSection(data: { id: string }) {
  const { id } = data;
  return apiInstance.delete(`itemCategories/${id}`).then((response) => response.data);
}

export function updateSection(data: { id: any; values: any }) {
  const { id, values } = data;
  return apiInstance
    .put(`itemCategories/${id}`, {
      ...values
    })
    .then((response) => response.data);
}

export function updateSectionsOrder(data: { sections: any }) {
  const { sections } = data;
  return apiInstance
    .put(`sections/update-order`, {
      sections
    })
    .then((response) => response.data);
}

export function getBrandSections(
  ctx: QueryFunctionContext<[string, { brandId?: string }]>
): Promise<GetBrandSectionsResponse> {
  const [, { brandId }] = ctx.queryKey;
  if (!brandId) {
    return Promise.resolve({
      itemCategories: [] as Section[]
    });
  }
  return apiInstance.get(`/brands/${brandId}/categories`).then((response) => response.data.data);
}
