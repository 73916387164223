import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from '../../utils/axios';
import { Profile } from '../../@types/user';

type UserState = {
  isLoading: boolean;
  error: boolean;
  myProfile: null | Profile;
  userList: Profile[];
};

const initialState: UserState = {
  isLoading: false,
  error: false,
  myProfile: null,
  userList: []
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    }
  }
});

export default slice.reducer;

export function getProfile() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/profile');
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/user/all');
      const users = [
        {
          name: 'name',
          company: 'company',
          role: 'role',
          isVerified: 'isVerified',
          status: 'status'
        },
        {
          name: 'name 2',
          company: 'company',
          role: 'role',
          isVerified: 'isVerified',
          status: 'status'
        }
      ];
      dispatch(slice.actions.getUsersSuccess(users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
