import { ReactNode } from 'react';
import { alpha, ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import componentsOverride from '../theme/overrides';
import { yellowColor } from '../contexts/SettingsContext';

type ThemePrimaryColorProps = {
  children: ReactNode;
};

export default function ThemePrimaryColor({ children }: ThemePrimaryColorProps) {
  const defaultTheme = useTheme();

  const themeOptions = {
    ...defaultTheme,
    palette: {
      ...defaultTheme.palette,
      primary: yellowColor
    },
    customShadows: {
      ...defaultTheme.customShadows,
      primary: `0 8px 16px 0 ${alpha(yellowColor.main, 0.24)}`
    }
  };

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
