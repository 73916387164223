import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import apiInstance from 'apis/api';
import { DoneCallback } from '../../types/utils';
import { AppDispatch, dispatch, getState, RootState, store } from '../store';

export type ItemExtra = {
  _id: string;
  extra: {
    _id: string;
    name: { ar: string; en: string };
    price: number;
    max: number;
    min: number;
    order: number;
  };
  choices: ItemExtraChoices[];
  rules: string;
};

export type ItemExtraChoices = {
  _id: string;
  name: { ar: string; en: string };
  price: number;
  choiceItemName_ar?: string;
  choiceItemName_en?: string;
  choiceItemPrice?: string;
};

export type Section = {
  _id: string;
  editable: boolean;
  name: { ar: string; en: string };
  description: string;
  items: MenuItemType[];
};

export type MenuItemType = {
  _id: string;
  name: { ar: string; en: string };
  description: { ar: string; en: string };
  logo: string;
  image: string;
  itemCategories?: string[];
  price: number;
  extras: ItemExtra[];
  priceOnSelection: boolean;
};

type CurrentItemExtras = {
  [key: string]: ItemExtra[];
};

type MenuState = {
  isLoading: boolean;
  error: boolean;
  sections: Section[];
  branchSections: Section[];
  branchItems: MenuItemType[];
  selectedSection: Section | null;
  selectedItem: MenuItemType | null;
  currentItemExtras: CurrentItemExtras;
  selectedItemExtras: ItemExtra | null;
  branchStatus: { status: string; cancelationReason: string; offPeriod: string };
};

const initialState: MenuState = {
  isLoading: false,
  error: false,
  branchSections: [],
  branchItems: [],
  sections: [],
  selectedSection: null,
  selectedItem: null,
  selectedItemExtras: null,
  currentItemExtras: {},
  branchStatus: {
    status: 'opening',
    cancelationReason: '',
    offPeriod: ''
  }
};

const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getInitialize(state) {
      const initSection = state.branchSections[0];
      state.isLoading = false;
      state.selectedSection = initSection;
    },

    getSectionsSuccess(state, action) {
      state.isLoading = false;
      state.sections = action.payload;
    },

    addSectionSuccess(state, action) {
      state.isLoading = false;
      state.sections = action.payload;
    },

    deleteSectionSuccess(state, action) {
      state.isLoading = false;
      state.branchSections = action.payload;
    },

    updateSectionSuccess(state, action) {
      state.isLoading = false;
      state.branchSections = action.payload;
    },

    setSelectedSectionSuccess(state, action) {
      state.isLoading = false;
      state.selectedSection = action.payload;
    },

    getItemsSuccess(state, action) {
      state.isLoading = false;
      state.sections = action.payload;
    },

    addItemSuccess(state, action) {
      // state.selectedItem = action.payload;
      state.branchItems.push(action.payload);
      state.isLoading = false;
    },

    deleteItemSuccess(state, action) {
      state.isLoading = false;
      state.branchItems = action.payload;
    },

    updateItemSuccess(state, action) {
      state.isLoading = false;
      state.branchItems = action.payload;
    },

    addItemExtrasSuccess(state, action) {
      state.isLoading = false;
      state.branchItems = action.payload;
    },

    addCurrentItemExtrasSuccess(state, action) {
      state.isLoading = false;
      state.currentItemExtras = action.payload;
    },

    updateItemExtrasSuccess(state, action) {
      state.isLoading = false;
      state.branchItems = action.payload;
    },

    setSelectedItemExtrasSuccess(state, action) {
      state.isLoading = false;
      state.selectedItemExtras = action.payload;
    },

    setSelectedItemSuccess(state, action) {
      state.isLoading = false;
      state.selectedItem = action.payload;
    },

    updateBranchStatusSuccess(state, action) {
      state.isLoading = false;
      state.branchStatus = action.payload;
    },

    getbranchSectionsSuccess(state, action) {
      state.isLoading = false;
      state.branchSections = action.payload;
    },

    getbranchItemsSuccess(state, action) {
      state.isLoading = false;
      state.branchItems = action.payload;
    }
  }
});

export default slice.reducer;

export function addSection(sectionItem: any) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const { currentBrandId } = store.getState().auth;

      const response = await apiInstance.post(`itemCategories/`, {
        brandId: currentBrandId,
        ...sectionItem
      });

      if (response.data.status) {
        dispatch(getCurrentBranchSections(currentBrandId!));
        dispatch(slice.actions.setSelectedSectionSuccess(response.data.data.itemCategory));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteSection(id: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.delete(`itemCategories/${id}`);
      if (response.data.status) {
        const sections = getState().menu.branchSections.filter((s) => s._id !== id);
        dispatch(slice.actions.deleteSectionSuccess(sections));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSection(id: any, values: {}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.put(`itemCategories/${id}`, {
        ...values
      });
      if (response.data.status) {
        const filteredSections = [...getState().menu.branchSections].filter((s) => s._id !== id);
        const sections = [...filteredSections, response.data.data.itemCategory].sort(
          (a, b) => a.order - b.order
        );

        dispatch(slice.actions.setSelectedSectionSuccess(response.data.data.itemCategory));
        dispatch(slice.actions.updateSectionSuccess(sections));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSectionsOrder(sections: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.put(`sections/update-order`, {
        sections
      });
      if (response.status) {
        dispatch(slice.actions.getbranchSectionsSuccess(response.data.data.itemCategories));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function setSelectedSection(id: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const currentSection = getState().menu.branchSections.find((s) => s._id === id);
      dispatch(slice.actions.setSelectedSectionSuccess(currentSection));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBrandItems(brandId: string, done?: DoneCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/brands/${brandId}/fetch-items`);
      if (response.status) {
        dispatch(slice.actions.getbranchItemsSuccess(response.data.data.items));
        done?.(null);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      done?.(error as Error);
    }
  };
}

export function getBranchItems(branchId: string, done?: DoneCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/branches/${branchId}/items`);
      if (response.status) {
        dispatch(slice.actions.getbranchItemsSuccess(response.data.data.items));
        done?.(null);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      done?.(error as Error);
    }
  };
}

export function addItem(sectionId: any, item: any, callback?: DoneCallback) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.post(`/sections/${sectionId}/add-items`, {
        ...item
      });
      if (response.status) {
        dispatch(slice.actions.addItemSuccess(response.data.data.item));
        callback?.(null, response.data.data.item);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      callback?.(error as Error);
    }
  };
}

export function deleteItem(itemId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.delete(`items/${itemId}`);
      if (response.data.status) {
        const items = getState().menu.branchItems.filter((s) => s._id !== itemId);
        dispatch(slice.actions.deleteItemSuccess(items));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateItem(
  sectionId: string,
  itemId: string,
  values: any,
  callback?: DoneCallback
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // [Check] - to be enhanced
      await apiInstance.put(`/sections/${sectionId}/${itemId}/update-item`, {
        ...values
      });

      dispatch(slice.actions.stopLoading());
      callback?.(null);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      callback?.(error as Error);
    }
  };
}

export function updateItemsOrder(sectionId: any, items: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.put(`/sections/${sectionId}/items-order`, {
        items
      });
      if (response.status) {
        const currentSection = getState().menu.branchSections.find((s) => s._id === sectionId);
        dispatch(slice.actions.setSelectedSectionSuccess(currentSection));

        dispatch(slice.actions.updateItemSuccess(response.data.data.items));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSelectedItem(itemId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/items/${itemId}`);
      if (response.status) {
        let { extras } = response.data.data.item;
        extras = extras.sort((a: any, b: any) =>
          // eslint-disable-next-line no-nested-ternary
          a.extra?.order > b.extra?.order ? 1 : b.extra?.order > a.extra?.order ? -1 : 0
        );

        const updatedItem = { extras, ...response.data.data.item };
        dispatch(slice.actions.setSelectedItemSuccess(updatedItem));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function addItemExtras(itemId: string, values: any) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await apiInstance.post(`/items/${itemId}/extras`, {
//         ...values
//       });

//       if (response.status) {
//         const currentItems = [...getState().menu.branchItems];
//         const targetitemIndex = currentItems?.findIndex((t) => t._id === itemId);
//         currentItems[targetitemIndex] = response.data.data.item;

//         dispatch(slice.actions.addItemExtrasSuccess(currentItems));
//         dispatch(slice.actions.setSelectedItemSuccess(response.data.data.item));
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
export function addItemExtras(sectionId: any, values: any) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const currentExtras = { ...getState().menu.currentItemExtras };

      if (currentExtras[sectionId]) {
        currentExtras[sectionId] = [...currentExtras[sectionId], values];
      } else {
        currentExtras[sectionId] = [].concat(values);
      }
      dispatch(slice.actions.addCurrentItemExtrasSuccess(currentExtras));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateItemExtras(itemId: any, itemExtraId: any, values: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.put(`/items/${itemId}/extras/${itemExtraId}`, {
        ...values
      });

      if (response.status) {
        const currentItems = [...getState().menu.branchItems];
        const targetitemIndex = currentItems?.findIndex((t) => t._id === itemId);
        currentItems[targetitemIndex] = response.data.data.item;

        dispatch(slice.actions.updateItemExtrasSuccess(currentItems));
        dispatch(slice.actions.setSelectedItemSuccess(response.data.data.item));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteItemExtras(itemId: string, itemExtraId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.delete(
        `/items/${itemId}/extras/${itemExtraId}/delete-extra`
      );

      if (response.status) {
        const currentItems = [...getState().menu.branchItems];
        const targetitemIndex = currentItems?.findIndex((t) => t._id === itemId);
        currentItems[targetitemIndex] = response.data.data.item;

        let { extras } = response.data.data.item;
        extras = extras.sort((a: any, b: any) =>
          // eslint-disable-next-line no-nested-ternary
          a.extra?.order > b.extra?.order ? 1 : b.extra?.order > a.extra?.order ? -1 : 0
        );

        const updatedItem = { extras, ...response.data.data.item };
        dispatch(slice.actions.updateItemExtrasSuccess(currentItems));
        dispatch(slice.actions.setSelectedItemSuccess(updatedItem));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSelectedItemExtras(itemExtras: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setSelectedItemExtrasSuccess(itemExtras));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCurrentBranchSections(branchId: string) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/branches/${branchId}/categories`);
      if (response.status) {
        dispatch(slice.actions.getbranchSectionsSuccess(response.data.data.itemCategories));
        dispatch(slice.actions.getInitialize());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCurrentBrandSections(brandId: string) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/brands/${brandId}/categories`);
      if (response.status) {
        dispatch(slice.actions.getbranchSectionsSuccess(response.data.data.itemCategories));
        dispatch(slice.actions.getInitialize());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function getCurrentBranchSections(brandId: string) {
//   return async (dispatch: any, getState: any) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await apiInstance.get(`/brands/${brandId}/categories`);
//       if (response.status) {
//         dispatch(slice.actions.getbranchSectionsSuccess(response.data.data.itemCategories));
//         dispatch(slice.actions.getInitialize());
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function setSelectedItem(id: any, sectionId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const currentItem = [...getState().menu.branchItems].find((s) => s._id === id);
      // const currentItem = section?.items.find((s) => s._id === id);
      dispatch(slice.actions.setSelectedItemSuccess(currentItem));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
