import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import apiInstance, { unProtectedApiInstance } from 'apis/api';
import { AppDispatch } from 'store/store';
import { AuthState } from '../../types/user';
import { DoneCallback } from '../../types/utils';

type IBrand = {
  _id: string;
};

type IUser = {
  _id: string;
  user: {
    phoneVerified: boolean;
    emailVerified: boolean;
    status: boolean;
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    avatar: string;
    phone: string;
    address: string;
    createdAt: string;
    updatedAt: string;
    branch: string;
    brandId: IBrand;
  };
  createdAt: string;
  updatedAt: string;
};

const initialState: AuthState = {
  isLoading: false,
  isAuthenticated: false,
  resetTokenSent: null,
  isSuperAdmin: false,
  user: null,
  errors: {},
  isSuccess: false,
  currentRolePermissions: {},
  permissionList: [],
  branches: [],
  brands: [],
  currentBranch: null,
  currentBranchId: null,
  currentBrandId: null
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.errors = action.payload;
    },

    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.currentRolePermissions = action.payload.permissions;
      state.isSuccess = true;
    },

    loginSuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.currentRolePermissions = action.payload.permissions;
      state.isSuccess = true;
      state.errors = {};
    },

    updatedSuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    userUpdatedSuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true;
    },

    markAsSuperAdmin(state, action) {
      state.isSuperAdmin = true;
    },

    currentRolePermissionsSuccess(state, action) {
      state.currentRolePermissions = action.payload.permissions;
    },

    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.currentRolePermissions = {};
      state.permissionList = [];
      state.user = null;
      state.errors = {};
      state.branches = [];
      state.brands = [];
      state.currentBranchId = null;
      state.currentBranch = null;
      state.currentBrandId = null;
    },

    updateBranchesSuccess(state, { payload }) {
      const branch = payload[0];
      state.isLoading = false;
      state.branches = payload;
      state.currentBranch = branch;
      state.currentBranchId = branch._id;
      state.currentBrandId = branch.brand;
    },

    updateBrandsSuccess(state, { payload }) {
      state.isLoading = false;
      state.brands = payload;
    },

    setCurrentBranchSuccess(state, { payload }) {
      state.isLoading = false;
      state.currentBranch = payload;
      state.currentBranchId = payload._id;
      state.currentBrandId = payload.brand;
    },

    clearCurrentBranchSuccess(state) {
      state.isLoading = false;
      // state.currentBranch = payload;
      state.currentBranchId = null;
      // state.currentBrandId = payload.brand;
    },

    setCurrentBranchOpeningTimes(state, { payload }) {
      state.isLoading = false;
      state.currentBranch = payload;
    },

    setBrandIdSuccess(state, { payload }) {
      state.currentBrandId = payload;
    },

    resetPasswordTokenSentSuccess(state) {
      state.resetTokenSent = null;
    }
  }
});

export default slice.reducer;

const setSession = (accessToken: any) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export function login({ email, password }: { email: string; password: string }) {
  return async (dispatch: any) => {
    const response = await unProtectedApiInstance.post('/auth/login', {
      email,
      password
    });

    if (response.status) {
      const { user, token } = response.data.data;
      if (user && token) {
        dispatch(slice.actions.setBrandIdSuccess(user.user._id));
        dispatch(slice.actions.loginSuccess(user.user));
        dispatch(slice.actions.updateBranchesSuccess(user.branches));
        dispatch(slice.actions.updateBrandsSuccess(user.brands));
      }

      setSession(token);
    } else {
      const error = response.data.errors.name;
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function logout() {
  return async (dispatch: any) => {
    setSession(null);
    dispatch(slice.actions.logoutSuccess());
  };
}

export function forgetPassword(email: string) {
  return async (dispatch: any) => {
    const response = await unProtectedApiInstance.post('/password/forget', {
      email
    });
    const { status } = response.data;
    if (status) {
      dispatch(slice.actions.resetPasswordTokenSentSuccess());
    }
  };
}

export function resetPassword(
  token: string,
  { password, confirmPassword }: { password: string; confirmPassword: string }
) {
  return async (dispatch: any) => {
    const response = await unProtectedApiInstance.put(`/password/reset/${token}`, {
      password,
      confirmPassword
    });
    const { status } = response.data;
    if (status) {
      dispatch(slice.actions.resetPasswordTokenSentSuccess());
    }
  };
}

export function updateProfile(values: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.put('/profile/me/update', values);
      if (response.data.status) {
        // dispatch(getInitialize());
        // dispatch(slice.actions.updatedSuccess(response.data.user));
        dispatch(slice.actions.userUpdatedSuccess({}));
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function getAllBranches(brandId: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/brands/${brandId}/branches`);
      if (response.data.status) {
        dispatch(slice.actions.updateBranchesSuccess(response.data.branches));
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function getCurrentBranchData(branchId: string) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/branches/${branchId}`);

      dispatch(slice.actions.setCurrentBranchSuccess(response.data.data.branch));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateBranchStatus(values: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const { status, cancelationReason, offPeriod } = values;

      const response = await apiInstance.put(`/branches/update-status`, {
        status,
        time: offPeriod,
        reason: cancelationReason
      });
      dispatch(slice.actions.setCurrentBranchSuccess(response.data.data.branch));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateBranchWormingDays(values: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const { opensAt, closesAt, day, status } = values;

      const response = await apiInstance.put(`/branches/set-working-hours`, {
        opensAt,
        closesAt,
        day,
        status
      });
      dispatch(slice.actions.setCurrentBranchOpeningTimes(response.data.data.branch));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setItemAvailabilityStatus(branchIds: string[], values: any, done?: DoneCallback) {
  const { id, status } = values;

  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.put(
        `/branches/update-item-status`,
        {
          period: '',
          itemId: id,
          status
        },
        { headers: { branch: branchIds } }
      );
      dispatch(slice.actions.setCurrentBranchSuccess(response.data.data.item));
      done?.(null);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      done?.(error as Error);
    }
  };
}

export function setItemAvailabilityPeriod(itemId: string, period: number, done?: DoneCallback) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.put(`/branches/update-item-availabilty`, {
        period,
        itemId
      });
      dispatch(slice.actions.setCurrentBranchSuccess(response.data.data.item));
      done?.(null);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      done?.(error as Error);
    }
  };
}

export function setCurrentBranch(branchId: string) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const currentBranch = [...getState().auth.branches].find((b) => b._id === branchId);
      dispatch(slice.actions.setCurrentBranchSuccess(currentBranch));
    } catch (error) {
      console.error(error);
    }
  };
}
export function clearCurrentBranch() {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(slice.actions.clearCurrentBranchSuccess());
    } catch (error) {
      console.error(error);
    }
  };
}
export function getInitialize() {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/profile/me`);
      const { user } = response.data.data;
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: true,
          user
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }
  };
}

export function setError(error: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.hasError(error));
  };
}
