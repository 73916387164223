import { Box, Divider, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import ContactsDetails from './ContactsDetails';
import OrderItemsDetails from './OrderItemsDetails';

function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
  return { name, calories, fat, carbs, protein };
}

const BASIC_TABLE = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3)
];

export default function OrderDetails({ orderDetails, orderId }: any) {
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={{ background: '#01645c', color: '#fff', p: 2 }}>
        {t('orders.orderNo')}: {orderDetails?.orderNo}
      </Box>
      <Stack direction="row" justifyContent="space-between" sx={{ px: 3, pb: 3 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          {orderDetails?.status && orderDetails?.status[1] && (
            <Stack direction="column" alignItems="center" spacing={1} sx={{ my: 1 }}>
              <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: 0.5,
                    bgcolor: 'success.main',
                    mx: 1
                  }}
                />
                <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: 11 }}>
                  {t('orders.issuedTime')}
                </Typography>
              </Stack>
              <Typography variant="h6">
                {format(new Date(orderDetails?.status[1]?.at), 'hh:mm')}
              </Typography>
            </Stack>
          )}
          {orderDetails?.status && orderDetails?.status[2] && (
            <Stack direction="column" alignItems="center" spacing={1} sx={{ my: 1 }}>
              <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: 0.5,
                    bgcolor: 'success.main',
                    mx: 1
                  }}
                />
                <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: 11 }}>
                  {t('orders.acceptedTime')}
                </Typography>
              </Stack>
              <Typography variant="h6">
                {format(new Date(orderDetails?.status[2]?.at), 'hh:mm')}
              </Typography>
            </Stack>
          )}
          {orderDetails?.status && orderDetails?.status[3] && (
            <Stack direction="column" alignItems="center" spacing={1} sx={{ my: 1 }}>
              <Stack direction="row">
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: 0.5,
                    mx: 1,
                    bgcolor: 'success.main'
                  }}
                />
                <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: 11 }}>
                  {t('orders.readyTime')}
                </Typography>
              </Stack>
              <Typography variant="h6">
                {format(new Date(orderDetails?.status[3]?.at), 'hh:mm')}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Divider />
      <Box sx={{ m: 3 }}>
        <ContactsDetails orderDetails={orderDetails} />
      </Box>
      {/* <Divider /> */}
      <Box>
        <OrderItemsDetails order={orderDetails} />
      </Box>
    </Box>
  );
}
