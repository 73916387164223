import edit2Outline from '@iconify/icons-eva/edit-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import moreVerticalOutline from '@iconify/icons-eva/more-vertical-outline';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { uploadPhoto } from 'apis/uploadPhoto';
import { UploadAvatar } from 'components/upload';
import { Form, FormikProvider, useFormik } from 'formik';
import useIsMountedRef from 'hooks/useIsMountedRef';
import i18next from 'i18next';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { localize } from 'utils/localize';
import * as Yup from 'yup';
import {
  DraggableList,
  DraggableListRenderItemProps
} from '../../../components/utils/DraggableList';
import { useDeleteDialog } from '../../../hooks/useDeleteDialog';
import TransitionsDialogs from '../../../pages/menu/Dialog';
import { PATH_DASHBOARD } from '../../../routes/paths';
import {
  addItem,
  deleteItem,
  deleteItemExtras,
  getBrandItems,
  getItemDetails,
  updateItem
} from '../../../services/items.service';
import { ItemExtra } from '../../../store/slices/menu';
import { MongoID } from '../../../types/menu.types';
import { ArrayUtils } from '../../../utils/array-utils';
import { ItemExtraForm } from './ItemExtraForm';

type ItemNewFormProps = {
  create?: boolean;
  edit?: boolean;
};

const MENU_OPTIONS = ['Edit', 'Delete'];

export default function ItemForm({ create, edit }: ItemNewFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [isMenuOpen, setMenuOpen] = useState<null | HTMLDivElement>(null);
  const [isExtraMenuOpen, setExtraMenuOpen] = useState<null | HTMLDivElement>(null);
  const [isConfirm, setConfirmOpen] = useState<boolean>(false);
  const [isExtrasEditable, setIsExtrasEditable] = useState<boolean>(false);
  const [currentExtraId, setCurrentExtraId] = useState<string | null>(null);
  const [extraIndex, setExtraIndex] = useState<number | null>(0);
  const { sectionId, itemId, brandId } = useParams();

  const isMounted = useIsMountedRef();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { openDeleteDialog, closeDeleteDialog, deleteDialog } = useDeleteDialog({
    title: t('app.important'),
    message: t('messages.deleteConfirm'),
    onConfirm: () => {
      if (itemId) {
        deleteItemMutation.mutate(
          { itemId },
          {
            onSuccess() {
              refetchItems();
              navigate(
                PATH_DASHBOARD.general.section
                  .replace(':brandId', brandId ?? '')
                  .replace(':sectionId', sectionId ?? '')
              );
            }
          }
        );
      }
      closeDeleteDialog();
    }
  });

  const {
    data: itemResponse,
    isFetching: isFetchingItem,
    refetch: refetchItem
  } = useQuery(['item', { brandId, itemId }], getItemDetails);

  const { refetch: refetchItems } = useQuery(['items', { brandId }], getBrandItems);

  const addItemMutation = useMutation(addItem);
  const deleteItemMutation = useMutation(deleteItem);
  const deleteItemExtrasMutation = useMutation(deleteItemExtras);
  const updateItemMutation = useMutation(updateItem);

  useEffect(() => {
    refetchItem();
  }, [itemId, refetchItem]);

  const sortedExtras = useMemo(() => {
    if (itemResponse?.item?.extras == null) {
      return null;
    }

    return [...itemResponse.item.extras].sort((a, b) => a.extra.order - b.extra.order) || null;
  }, [itemResponse?.item?.extras]);

  const handleAddItemOpen = () => {
    setOpen(true);
  };

  const handleAddItemClose = () => {
    setOpen(false);
  };

  const handleExtraMenuOpen = (event: React.MouseEvent<HTMLDivElement>, extraId: string | null) => {
    setExtraMenuOpen(event.currentTarget);
    setCurrentExtraId(extraId);
  };

  const handleExtraMenuClose = () => {
    setExtraMenuOpen(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement>, id: string, index: any) => {
    setMenuOpen(event.currentTarget);
    setCurrentExtraId(id);
    setExtraIndex(index);
  };

  const handleMenuClose = () => {
    setMenuOpen(null);
  };

  const confirmationBoxOpen = () => {
    setConfirmOpen(true);
  };

  const confirmationBoxClose = () => {
    setConfirmOpen(false);
  };

  const openAddNewItemExtraDialog = () => {
    setCurrentExtraId(null);
    setExtraIndex(null);
    setIsExtrasEditable(false);
    handleAddItemOpen();
  };

  const handleUserAction = (option: string) => {
    if (option === 'Delete') {
      handleMenuClose();
      confirmationBoxOpen();
    }
    if (option === 'Edit') {
      handleMenuClose();
      setIsExtrasEditable(true);
      handleAddItemOpen();
    }
  };

  const handleMenuUserAction = (option: string) => {
    if (option === 'Delete') {
      openDeleteDialog();
      handleExtraMenuClose();
    }

    if (option === 'Edit') {
      handleExtraMenuClose();
      setIsExtrasEditable(true);
      navigate(
        PATH_DASHBOARD.general.editItem
          .replace(':brandId', brandId ?? '')
          .replace(':sectionId', sectionId ?? '')
          .replace(':itemId', itemId ?? '')
      );
    }
  };

  const userConfirmAction = () => {
    if (itemId && currentExtraId) {
      deleteItemExtrasMutation.mutate(
        { itemId, itemExtraId: currentExtraId },
        {
          onSuccess() {
            enqueueSnackbar('Deleted successfully', { variant: 'success' });
            refetchItem();
          }
        }
      );
    }
    confirmationBoxClose();
  };

  const ItemFormSchema = Yup.object().shape({
    name_ar: Yup.string().required(t('menu.nameArValidate')),
    name_en: Yup.string().required(t('menu.nameEnValidate')),
    description: Yup.string(),
    // logo: Yup.string(),
    price: Yup.string().required(t('menu.priceValidate')),
    extras: Yup.array().of(Yup.object())
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name_en: !create ? itemResponse?.item?.name.en : '',
      name_ar: !create ? itemResponse?.item?.name.ar : '',
      description_ar: !create ? itemResponse?.item?.description.ar : '',
      description_en: !create ? itemResponse?.item?.description.en : '',
      logo: !create ? itemResponse?.item?.image : '',
      price: !create ? itemResponse?.item?.price : '0',
      extras: !create ? sortedExtras : [],
      priceOnSelection: !create ? itemResponse?.item?.priceOnSelection : false
      // extras: (!create && item?.extras) || (!itemId ? extrasList : [])
    },
    validationSchema: ItemFormSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      const {
        name_ar,
        name_en,
        description_ar,
        description_en,
        logo,
        price,
        extras,
        priceOnSelection
      } = values;

      const item = {
        name: { en: name_en, ar: name_ar },
        description: { en: description_en, ar: description_ar },
        image: logo,
        price,
        extras,
        priceOnSelection
      };

      const viewItemUrl = PATH_DASHBOARD.general.item
        .replace(':brandId', brandId ?? '')
        .replace(':sectionId', sectionId ?? '');

      if (create && sectionId) {
        addItemMutation.mutate(
          {
            sectionId,
            item
          },
          {
            onSuccess(data) {
              setSubmitting(false);
              resetForm();
              refetchItems();
              enqueueSnackbar('Item created successfully', { variant: 'success' });
              navigate(viewItemUrl.replace(':itemId', data?.data?.item?._id));
            }
          }
        );
      }

      if (!create && sectionId && itemId) {
        updateItemMutation.mutate(
          {
            sectionId,
            itemId,
            values: item
          },
          {
            onSuccess() {
              setSubmitting(false);
              resetForm();
              refetchItem();
              refetchItems();
              enqueueSnackbar('Item updated success', { variant: 'success' });
              navigate(viewItemUrl.replace(':itemId', itemId ?? ''));
            }
          }
        );
      }
    }
  });

  const { errors, values, touched, handleSubmit, setFieldValue, getFieldProps, isSubmitting } =
    formik;

  const handleDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        const data = await uploadPhoto(formData);
        if (isMounted.current) {
          data && setFieldValue('logo', data.url);
        }
        refetchItems();
      }
    },
    [setFieldValue, isMounted]
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      const { source, destination } = result;

      const extras = ArrayUtils.reorder(values.extras || [], source.index, destination.index);

      if (sectionId && itemId) {
        updateItemMutation.mutate(
          {
            sectionId,
            itemId,
            values: {
              extras
            }
          },
          {
            onSuccess() {
              refetchItem();
              // refetchItems();
              enqueueSnackbar('Extras re-ordered successfully', { variant: 'success' });
            }
          }
        );
      }
    },
    [values.extras]
  );

  let itemName;
  if (create) {
    itemName = t('menu.addNewItem');
  } else {
    itemName = localize(itemResponse?.item?.name);
  }

  return (
    <FormikProvider value={formik}>
      {deleteDialog}
      <TransitionsDialogs
        open={open}
        size="sm"
        title={!isExtrasEditable ? t('menu.addItemExtra') : t('menu.updateItemExtra')}
        handleClose={handleAddItemClose}
      >
        <ItemExtraForm
          edit={isExtrasEditable}
          item={itemResponse?.item}
          extraIndex={extraIndex}
          extrasList={values.extras}
          onCancel={handleAddItemClose}
          onSubmit={(value) => {
            setFieldValue('extras', value);
          }}
        />
      </TransitionsDialogs>

      <TransitionsDialogs
        size="sm"
        open={isConfirm}
        handleClose={confirmationBoxClose}
        handleConfirm={() => {
          if (!itemId) {
            const filteredExtras = values?.extras?.filter(
              (e: any) => e.extra._id !== currentExtraId
            );
            setFieldValue('extras', filteredExtras);
          }
          userConfirmAction();
        }}
        title={t('app.important')}
      >
        {t('messages.deleteConfirm')}
      </TransitionsDialogs>

      {!isFetchingItem ? (
        <Form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{ position: 'relative' }}
        >
          <Backdrop
            open={
              isFetchingItem || deleteItemExtrasMutation.isLoading || deleteItemMutation.isLoading
            }
            sx={{ position: 'absolute', zIndex: 2 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
                <Typography variant="subtitle1">{itemName}</Typography>
                <Box>
                  {!edit && (
                    <Box onClick={(e) => itemId && handleExtraMenuOpen(e, null)}>
                      <Icon icon={moreVerticalOutline} width={20} height={20} />
                    </Box>
                  )}
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box sx={{ mb: 5 }}>
                <UploadAvatar
                  accept="image/*"
                  file={values.logo ?? ''}
                  maxSize={35000}
                  onDrop={handleDrop}
                  error={Boolean(touched.logo && errors.logo)}
                  caption={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary'
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of 35kb
                    </Typography>
                  }
                  disabled={!edit}
                />
                <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                  {touched.logo && errors.logo}
                </FormHelperText>
              </Box>
            </Grid>

            <Grid item xs={12} md={9}>
              <Stack direction={{ xs: 'column' }} spacing={3}>
                <TextField
                  fullWidth
                  label={t('menu.nameEn')}
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  {...getFieldProps('name_en')}
                  error={Boolean(touched.name_en && errors.name_en)}
                  helperText={touched.name_en && errors.name_en}
                  disabled={!edit}
                />
                <TextField
                  fullWidth
                  label={t('menu.nameAr')}
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  {...getFieldProps('name_ar')}
                  error={Boolean(touched.name_ar && errors.name_ar)}
                  helperText={touched.name_ar && errors.name_ar}
                  disabled={!edit}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={12}>
              <Stack direction={{ xs: 'row' }} spacing={3}>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  label={t('menu.descEn')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  {...getFieldProps('description_en')}
                  error={Boolean(touched.description_en && errors.description_en)}
                  helperText={touched.description_en && errors.description_en}
                  disabled={!edit}
                />
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  label={t('menu.descAr')}
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  {...getFieldProps('description_ar')}
                  error={Boolean(touched.description_ar && errors.description_ar)}
                  helperText={touched.description_ar && errors.description_ar}
                  disabled={!edit}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={12}>
              <Stack direction={{ xs: 'row' }} spacing={3}>
                <Typography variant="h6" sx={{ textAlign: 'center', alignSelf: 'center' }}>
                  {t('menu.price')}
                </Typography>
                <TextField
                  size="small"
                  {...getFieldProps('price')}
                  error={Boolean(touched.price && errors.price)}
                  helperText={touched.price && errors.price}
                  sx={{ background: 'lemonchiffon' }}
                  disabled={values.priceOnSelection}
                />
                <Typography variant="h6" sx={{ textAlign: 'center', alignSelf: 'center' }}>
                  {t('menu.priceOnSelection')}
                </Typography>
                <Switch
                  {...getFieldProps('priceOnSelection')}
                  color="secondary"
                  value={values.priceOnSelection}
                  checked={values.priceOnSelection}
                  onChange={(e) => {
                    setFieldValue('priceOnSelection', e.target.checked);
                  }}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={12}>
              <Paper
                sx={{
                  p: 3,
                  my: 2,
                  bgcolor: 'background.neutral'
                }}
              >
                <Stack
                  direction={{ xs: 'row' }}
                  sx={{ mb: 1, justifyContent: 'space-between' }}
                  spacing={2}
                >
                  <Typography variant="subtitle1" sx={{ textAlign: 'center', alignSelf: 'center' }}>
                    {t('menu.modifiers')}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={openAddNewItemExtraDialog}
                    // disabled={}
                  >
                    {t('menu.addNewModifier')}
                  </Button>
                </Stack>
                <DraggableList
                  idField="extra.name.en"
                  list={values?.extras || []}
                  onDragEnd={onDragEnd}
                  renderItem={renderItem}
                  itemProps={{
                    sectionId: sectionId ?? '',
                    handleUserAction,
                    handleMenuOpen,
                    handleMenuClose,
                    isMenuOpen,
                    edit: !!edit
                  }}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={12}>
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {t('app.saveChanges')}
              </LoadingButton>
            </Grid>
          </Grid>

          <Menu
            keepMounted
            id="simple-menu"
            anchorEl={isExtraMenuOpen}
            onClose={handleExtraMenuClose}
            open={Boolean(isExtraMenuOpen)}
          >
            <MenuItem onClick={() => handleMenuUserAction('Edit')}>
              <Icon icon={edit2Outline} width={20} height={20} />
              <Typography sx={{ mx: 1 }}>{t('app.edit')}</Typography>
            </MenuItem>
            <MenuItem onClick={() => handleMenuUserAction('Delete')}>
              <Icon icon={edit2Outline} width={20} height={20} />
              <Typography sx={{ mx: 1 }}>{t('app.delete')}</Typography>
            </MenuItem>
          </Menu>
        </Form>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress color="inherit" />
        </Box>
      )}
    </FormikProvider>
  );
}

function renderItem(
  props: DraggableListRenderItemProps<
    ItemExtra & MongoID,
    {
      handleUserAction: any;
      handleMenuOpen: any;
      handleMenuClose: any;
      isMenuOpen: HTMLDivElement | null;
      edit: boolean;
    }
  >
) {
  const { provided, item, index, itemProps } = props;
  const { handleUserAction, handleMenuOpen, handleMenuClose, isMenuOpen, edit } = itemProps;

  return (
    <>
      <Box
        key={item._id}
        sx={{
          boxShadow: 2,
          borderRadius: 1,
          p: 1,
          px: 2,
          my: 2,
          background: '#fff',
          width: '100%'
        }}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        // {...getFieldProps('extras')}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Box
            sx={{
              cursor: 'pointer',
              paddingTop: '5px'
            }}
            onClick={(e) => handleMenuOpen(e, item?.extra?._id, index)}
          >
            {edit ? <Icon icon={moreVerticalFill} width={20} height={20} /> : null}
          </Box>
          <Typography variant="body2" sx={{ flexGrow: 10 }}>
            {item?.extra?.name && localize(item?.extra?.name)}
          </Typography>
        </Stack>
      </Box>
      <Menu
        keepMounted
        id="simple-menu"
        anchorEl={isMenuOpen}
        onClose={handleMenuClose}
        open={Boolean(isMenuOpen)}
      >
        {MENU_OPTIONS.map((option) => (
          <MenuItem key={option} onClick={() => handleUserAction(option)}>
            <Icon
              icon={(option === 'delete' && trash2Outline) || edit2Outline}
              width={20}
              height={20}
            />
            <Typography sx={{ mx: 1 }}>{i18next.t(`app.${option.toLowerCase()}`)}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
