import { useState } from 'react';
import { Typography, Card, Stack, Divider, Box, Button } from '@mui/material';
import { format, differenceInMinutes } from 'date-fns';
import useCountdown from 'hooks/useCountdown';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { getOrderIdHash } from 'utils/orders';
import ViewOrderDetails from '../ordersList/ViewOrderDetails';
import OrderDetails from '../ordersList/OrderDetails';
import { OrderStatus } from '../../../utils/OrderStatus';
import { localize } from '../../../utils/localize';

interface CurrentOrderCardProps {
  currentOrdersDetails: any;
  setOrderToReadyState: (id: string) => void;
  id: string;
  isDisabled: boolean;
}

export default function CurrentPendingOrderCard({
  currentOrdersDetails,
  setOrderToReadyState,
  isDisabled,
  id
}: CurrentOrderCardProps) {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const { createdAt } = currentOrdersDetails;
  const timeDifference = differenceInMinutes(new Date(), new Date(createdAt));
  const timeLimit = 45;
  const [timeLeft, setTimeLeft] = useState(timeLimit - timeDifference);

  const countdown = useCountdown(timeLeft);
  const { t } = useTranslation();

  const handleButtonClick = (e: React.MouseEvent<HTMLElement>, id: string) => {
    e.stopPropagation();
    setTimeLeft(0);
    setOrderToReadyState(id);
  };

  const handleDrawerOpen = () => {
    setIsDetailsOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDetailsOpen(false);
  };

  const { orderNo } = currentOrdersDetails;
  const orderStatus = new OrderStatus(currentOrdersDetails);

  return (
    <>
      <ViewOrderDetails open={isDetailsOpen} handleDrawerClose={handleDrawerClose}>
        <OrderDetails orderDetails={currentOrdersDetails} orderId={id} />
      </ViewOrderDetails>
      <Card sx={{ p: 2, mb: 2, borderRadius: 1 }} onClick={handleDrawerOpen}>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ borderLeft: '3px solid #01645c', paddingLeft: 1 }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              {localize(currentOrdersDetails?.orderDetails?.brand?.name)}
              {' / '}
              {localize(currentOrdersDetails?.orderDetails?.branch?.name)} ({orderNo})
            </Typography>
            <Typography variant="body2" sx={{ fontSize: 12 }}>
              {t('orders.issuedAt')} : {createdAt && format(new Date(createdAt), 'hh:mm')}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            sx={{ color: '#fff', backgroundColor: '#01645c' }}
            onClick={(e) => handleButtonClick(e, id)}
            disabled={isDisabled || orderStatus.isInProgress === false}
          >
            {orderStatus.isInProgress ? t('orders.readyForDelivery') : <CheckIcon />}
          </Button>
        </Stack>
        <Divider sx={{ py: 1 }} />
        <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'end' }}>
          <Box sx={{ mt: 1 }}>
            <Typography variant="body2" sx={{ width: '100%', fontSize: '14px', color: '#666' }}>
              {orderStatus}
            </Typography>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Typography sx={{ fontWeight: 'bold' }}>
              {orderStatus.isInProgress ? '' : t('app.done')}
            </Typography>
          </Box>
        </Stack>
      </Card>
    </>
  );
}
