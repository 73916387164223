import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Stack,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderRejectionReasons } from '../../../store/slices/orders';
import { RootState } from '../../../store/store';
import { localize } from '../../../utils/localize';

interface InitialState {
  reason: string | undefined;
  afterSubmit?: boolean;
}

type ItemExtrasNewFormProps = {
  handleClose: () => void;
  handleRejection: (reasonId?: string) => void;
};

const PERIODS_OPTION = [
  { id: 0, period: '30 min' },
  { id: 1, period: '1 min' },
  { id: 2, period: '2 h' },
  { id: 3, period: 'always' }
];

const CANCEL_REASON_OPTION = [
  { id: 0, label: 'No Drivers Available' },
  { id: 1, label: 'Technical issue' },
  { id: 2, label: 'no Resources Available' },
  { id: 3, label: 'other' }
];

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

export default function SelectRejectionReason({
  handleClose,
  handleRejection
}: ItemExtrasNewFormProps) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const rejectionReasons = useSelector((state: RootState) => state.orders.rejectionReasons);

  useEffect(() => {
    dispatch(getOrderRejectionReasons());
  }, []);

  const UpdateUserSchema = Yup.object().shape({
    reason: Yup.string().required(
      localize({ en: 'Cancelation Reason is required', ar: 'سبب الرفض مطلوب' })
    )
  });

  const formik = useFormik<InitialState>({
    enableReinitialize: true,
    initialValues: {
      reason: ''
    },

    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      const { reason } = values;
      try {
        handleRejection(reason);
        resetForm();
        handleClose();
        enqueueSnackbar('Update success', { variant: 'success' });
        // if (isMountedRef.current) {
        //   setSubmitting(false);
        // }
      } catch (error: any) {
        setErrors({ afterSubmit: error.code });
        setSubmitting(false);
      }
    }
  });

  const { errors, isSubmitting, handleSubmit, setFieldValue } = formik;

  return (
    <Stack>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Paper
            sx={{
              p: 3,
              my: 2,
              bgcolor: 'background.neutral'
            }}
          >
            <Stack direction={{ xs: 'column', md: 'column' }} sx={{ mb: 1 }} spacing={2}>
              <FormControl fullWidth>
                {/* <InputLabel>Cancelation reason</InputLabel> */}
                <Autocomplete
                  onChange={(_, o) => setFieldValue('reason', o?._id || '')}
                  options={rejectionReasons}
                  getOptionLabel={(o) => localize(o.reason)}
                  renderInput={(props) => (
                    <TextField
                      label={localize({ en: 'Cancellation reason', ar: 'سبب الرفض' })}
                      error={errors.reason !== '' && errors.reason != null}
                      helperText={errors.reason ? errors.reason : undefined}
                      {...props}
                    />
                  )}
                />
                {/* <Select label="Reason ..." native {...getFieldProps('reason')}>
                  {rejectionReasons.map(({ reason, _id }) => (
                    <React.Fragment key={_id}>
                      <option value={_id}>{localize(reason)}</option>
                    </React.Fragment>
                  ))}
                </Select> */}
              </FormControl>
            </Stack>
          </Paper>
          <Stack>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Save Changes
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </Stack>
  );
}
