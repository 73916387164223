import { Box, CircularProgress } from '@material-ui/core';
import { Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import SectionsBadgeIcon from '../../../assets/SectionsBadgeIcon';
import {
  DraggableList,
  DraggableListRenderItemProps
} from '../../../components/utils/DraggableList';
import MenuItemCard from '../../../pages/menu/menuManagement/menuItemsList/MenuItemCard';
import { getBrandItems, updateItemsOrder } from '../../../services/items.service';
import { getBrandSections } from '../../../services/sections.service';
import { Item, MongoID } from '../../../types/menu.types';
import { ArrayUtils } from '../../../utils/array-utils';
import { localize } from '../../../utils/localize';

export function ItemsList() {
  const params = useParams();
  const { t } = useTranslation();
  const { brandId, sectionId } = params;

  const [sortedItems, setSortedItems] = useState<Item[]>([]);

  const { data: sections } = useQuery(['sections', { brandId }], getBrandSections);

  const {
    data: items,
    isFetching: isFetchingItems,
    refetch: refetchItems
  } = useQuery(['items', { brandId }], getBrandItems);

  const updateItemsOrderMutation = useMutation(updateItemsOrder);

  const getSectionItems = useCallback(
    (sectionId: string) =>
      items?.items?.filter((item) => item.itemCategories?.some((i: any) => i._id === sectionId)) ??
      [],
    [items]
  );

  const renderSectionName = useCallback(() => {
    const sectionItems = sectionId && getSectionItems(sectionId);
    const currentSection: any = sections?.itemCategories?.find((s) => s._id === sectionId);
    return (
      <Stack direction="column">
        <Typography variant="subtitle1" sx={{ m: 0, lineHeight: 0.9 }}>
          {currentSection
            ? ` ${localize({ en: currentSection?.name.en, ar: currentSection?.name.ar })}`
            : t('menu.undefined')}
        </Typography>
        <Typography variant="body2">
          {' '}
          {sectionItems?.length} {sectionItems && t('menu.items')}{' '}
        </Typography>
      </Stack>
    );
  }, [getSectionItems, sectionId, sections?.itemCategories, t]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      if (sectionId == null) {
        return;
      }

      const { source, destination } = result;
      const sectionItems = getSectionItems(sectionId ?? '');

      const items: Item[] = ArrayUtils.reorder(sectionItems, source.index, destination.index);

      // setSortedItems(items);
      updateItemsOrderMutation.mutate(
        { sectionId, items },
        {
          onSuccess() {
            refetchItems();
          }
        }
      );
    },
    [refetchItems, sectionId, sortedItems, updateItemsOrderMutation]
  );

  useEffect(() => {
    const sortedItems = items?.items?.sort((a: any, b: any) =>
      // eslint-disable-next-line no-nested-ternary
      a.order > b.order ? 1 : b.order > a.order ? -1 : 0
    );
    setSortedItems(sortedItems ?? []);
  }, [items]);

  return (
    <>
      {isFetchingItems ? (
        <Box
          sx={{
            py: 3
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : null}

      {!isFetchingItems ? (
        <>
          <Stack direction="row" spacing={1} sx={{ mb: 2, justifyContent: 'space-between' }}>
            <Box>
              <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                <SectionsBadgeIcon />
                <Box>{renderSectionName()} </Box>
              </Stack>
            </Box>
          </Stack>

          <DraggableList
            list={getSectionItems(sectionId ?? '')}
            onDragEnd={onDragEnd}
            renderItem={renderItem}
            itemProps={{ sectionId: sectionId ?? '' }}
          />
        </>
      ) : null}
    </>
  );
}

function renderItem(props: DraggableListRenderItemProps<Item & MongoID, { sectionId: string }>) {
  const { provided, item, itemProps } = props;
  const { sectionId } = itemProps;
  return <MenuItemCard provided={provided} item={item} sectionId={sectionId} />;
}
