import { Box, CardActionArea, Drawer, Stack, Tooltip } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { clearCurrentBranch, setCurrentBranch } from 'store/slices/auth';
import { dispatch } from 'store/store';

import { useQuery } from 'react-query';
import { LogoIcon } from '../../assets';
import { MHidden } from '../../components/@material-extend';
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
import { useAuthUser } from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import { getProfile } from '../../services/auth.service';
import sidebarConfig from './SidebarConfig';

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 1.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2
          })
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

const navConfig = sidebarConfig;

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) {
  const { pathname } = useLocation();

  const { data } = useQuery('profile', getProfile, {
    refetchOnMount: true
  });

  const { user, branches, currentBranch } = useAuthUser();
  const [isOpen, setOpen] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  const [selectedBranch, setSelectedBranch] = useState<any>();
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const updateCurrentBranch = (branchId: any) => {
    if (branchId === 'all') {
      dispatch(clearCurrentBranch());
      setSelectedBranch(localStorage.getItem('i18nextLng') === 'ar' ? 'كل الفروع' : 'All Branches');
    } else {
      dispatch(setCurrentBranch(branchId));
      setSelectedBranch(
        localStorage.getItem('i18nextLng') === 'ar'
          ? currentBranch?.name.ar
          : currentBranch?.name.en
      );
    }
    // navigate(`${PATH_DASHBOARD.general.menu}/view`);
    handleClose();
  };

  useEffect(() => {
    dispatch(clearCurrentBranch());
    setSelectedBranch(localStorage.getItem('i18nextLng') === 'ar' ? 'كل الفروع' : 'All Branches');
  }, []);

  useEffect(() => {
    if (data?.user?.owner) {
      navConfig.forEach((c) => {
        c.items = c.items.filter((item) => item.title !== 'users');
      });
    }
  }, [data]);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <Stack
          spacing={3}
          sx={{
            px: 2.5,
            pt: 3,
            pb: 2,
            ...(isCollapse && {
              alignItems: 'center'
            })
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {isCollapse ? (
              <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
                <img src="/static/smallIcon.png" alt="" width="50" />
              </Box>
            ) : (
              <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
                <Logo />
              </Box>
            )}

            <MHidden width="lgDown">
              {!isCollapse && (
                <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
              )}
            </MHidden>
          </Stack>

          {/* {isCollapse ? (
            <MyAvatar sx={{ mx: 'auto', mb: 2 }} />
          ) : (
            <AccountStyle>
              <Button
                variant="outlined"
                onClick={handleOpen}
                style={{ width: '100%', border: '1px solid #dedede', color: '#333' }}
              >
                {selectedBranch}
              </Button>
              <Menu
                keepMounted
                id="branches"
                anchorEl={isOpen}
                onClose={handleClose}
                open={Boolean(isOpen)}
                PaperProps={{
                  style: {
                    width: '22ch'
                  }
                }}
              >
                <MenuItem
                    onClick={() => updateCurrentBranch('all')}
                >
                  {localStorage.getItem('i18nextLng') === 'ar'? 'كل الفروع' : 'All Branches'}
                  
                </MenuItem>
                {branches?.map(({ _id, name }: any) => (
                  <MenuItem
                    key={_id}
                    onClick={() => updateCurrentBranch(_id)}
                    value={_id}
                    selected={_id === currentBranch?._id}
                  >
                    {localStorage.getItem('i18nextLng') === 'ar'? name?.ar : name?.en}
                  </MenuItem>
                ))}
              </Menu>
            </AccountStyle>
          )} */}
        </Stack>

        <NavSection navConfig={sidebarConfig} isShow={!isCollapse} />

        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
      {!isCollapse && (
        <Stack
          spacing={3}
          alignItems="center"
          sx={{ mt: 10, width: 1, lineHeight: 0, textAlign: 'center' }}
        >
          <LogoIcon />
        </Stack>
      )}
    </>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
