import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  TextField
} from '@material-ui/core';
import { Cancel, Check } from '@material-ui/icons';
import { Box, Stack, Typography } from '@mui/material';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBrandBranches, updateOrderBranch } from '../../../store/slices/orders';
import { RootState } from '../../../store/store';
import { localize } from '../../../utils/localize';

export default function BrandDetails({ orderDetails }: any) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useReducer((prev: any, next: any) => ({ ...prev, ...next }), {
    isEditBranch: false,
    selectedBranch: null,
    currentOrderBranch: orderDetails?.orderDetails?.branch?.name
  });

  const brand = useSelector((state: RootState) => state.orders.brand);

  const toggleEditBranch = useCallback(() => {
    const nextState = !state.isEditBranch;
    setState({ isEditBranch: nextState });

    if (nextState === true) {
      dispatch(getBrandBranches(orderDetails?.orderDetails?.brand?._id!));
    }
  }, [state.isEditBranch, orderDetails, dispatch]);

  return (
    <Box>
      <Stack alignItems="center" sx={{ my: 1 }}>
        <Box width={80} height={80}>
          <img
            style={{ objectFit: 'scale-down' }}
            src={orderDetails?.orderDetails?.brand?.logo}
            alt="logo"
          />
        </Box>
        {/* <Stack direction="row" gap="15px" alignItems="center" sx={{ mx: 2 }}> */}
        <Typography
          variant="body2"
          sx={{ fontSize: 16, color: '#01645c', fontWeight: 'bold', mb: 2 }}
        >
          {localize(orderDetails?.orderDetails?.brand?.name)}
        </Typography>
        <Box display="flex" width="100%" justifyContent="center">
          {state.isEditBranch ? (
            <Box display="flex" width="100%" position="relative">
              <Backdrop
                open={brand.branches.isLoadingEdit}
                sx={{ zIndex: 2, position: 'absolute' }}
              >
                <CircularProgress />
              </Backdrop>
              <Autocomplete
                openOnFocus
                style={{ flex: 1 }}
                loading={brand.branches.loading}
                options={brand.branches.list}
                getOptionLabel={(o) => localize(o.name)}
                renderInput={(params) => <TextField autoFocus {...params} />}
                onChange={(e, branch) => setState({ selectedBranch: branch })}
              />
              <IconButton onClick={toggleEditBranch}>
                <Cancel color="error" />
              </IconButton>
              {state.selectedBranch ? (
                <IconButton
                  onClick={() =>
                    dispatch(
                      updateOrderBranch(orderDetails._id, state.selectedBranch?._id, (error) => {
                        if (error) {
                          const message = get(error, 'response.data.message', error.message);
                          enqueueSnackbar(message, { variant: 'error' });
                          return;
                        }

                        setState({ currentOrderBranch: state.selectedBranch });
                        toggleEditBranch();
                      })
                    )
                  }
                >
                  <Check style={{ color: 'green' }} />
                </IconButton>
              ) : null}
            </Box>
          ) : (
            <Button onClick={toggleEditBranch}>
              <Typography variant="body2" sx={{ fontSize: 16, fontWeight: 'bold' }}>
                {localize(
                  state.currentOrderBranch?.name || orderDetails?.orderDetails?.branch?.name
                )}
              </Typography>
            </Button>
          )}
        </Box>
        {/* </Stack> */}
      </Stack>
    </Box>
  );
}
