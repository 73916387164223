import { useEffect } from 'react';
import useIsMountedRef from './useIsMountedRef';

export const useNotificationPermission = () => {
  const mounted = useIsMountedRef();

  useEffect(() => {
    if (!mounted || !window.Notification) {
      return;
    }

    if (['default', 'denied'].includes(window.Notification.permission)) {
      window.Notification.requestPermission();
    }
  }, []);
};
