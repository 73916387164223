import { Box, BoxProps } from '@mui/material';

export default function LogoIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        width="278"
        height="78"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <path id="a" d="M0 0h298v74.685H0z" />
          <path id="c" d="M5.262 4.411 0 8.821h21.046l-5.261-4.41L10.523 0z" />
          <path d="m0 .43.006.001 1.296-.43L1.298 0" id="g" />
          <path id="i" d="m0 .078 7.847.35L9.963.352 2.116 0z" />
          <path d="M1.296.001 0 .43l.004.001 1.298-.43L1.299 0l-.003.001" id="l" />
          <path id="n" d="m0 .078 8.49.387 2.076-.078L2.077 0z" />
          <path
            d="M3.81.492C3.676.194 3.202.23 2.995.483c-.206.253-.22.609-.225.937-.161-.227-.514-.263-.74-.104-.228.159-.324.469-.27.742-.115-.216-.447-.24-.632-.08-.185.16-.233.439-.174.678a.684.684 0 0 0-.828.202.7.7 0 0 0 .045.859l11.699.203a.772.772 0 0 0-.38-.729.757.757 0 0 0-.814.06 1.578 1.578 0 0 0-.232-.651c-.04.09-.174.089-.257.035-.083-.054-.141-.142-.227-.19-.2-.111-.44.025-.627.156-.027-.385-.568-.609-.856-.355-.048-.256-.1-.52-.247-.734-.147-.215-.417-.365-.665-.29-.039.012-.079.03-.119.022-.085-.014-.113-.12-.134-.203a1.209 1.209 0 0 0-.853-.864 1.196 1.196 0 0 0-1.157.348A.825.825 0 0 0 4.567 0H4.55a.825.825 0 0 0-.74.492"
            id="q"
          />
          <path
            d="M7.664.99C7.394.39 6.44.464 6.025.972c-.415.509-.444 1.224-.453 1.884-.324-.456-1.033-.528-1.49-.208-.458.319-.65.943-.541 1.493-.233-.435-.9-.483-1.272-.162-.373.32-.468.883-.35 1.363-.557-.275-1.295-.094-1.665.407-.37.502-.331 1.268.089 1.727l23.533.41a1.553 1.553 0 0 0-.763-1.466 1.523 1.523 0 0 0-1.637.12 3.17 3.17 0 0 0-.468-1.31c-.079.183-.35.179-.517.07-.166-.108-.284-.284-.457-.381-.4-.224-.885.049-1.26.313-.056-.773-1.144-1.225-1.722-.714-.097-.515-.2-1.047-.496-1.477-.297-.431-.841-.734-1.338-.582-.079.024-.159.059-.24.045-.17-.03-.229-.241-.27-.41A2.432 2.432 0 0 0 12.992.354a2.406 2.406 0 0 0-2.33.7A1.659 1.659 0 0 0 9.187 0h-.032a1.66 1.66 0 0 0-1.49.99"
            id="t"
          />
          <path
            d="M7.664.99C7.394.39 6.441.464 6.025.973c-.415.508-.444 1.224-.453 1.883-.324-.456-1.034-.528-1.49-.208-.458.32-.65.943-.541 1.493-.233-.435-.9-.483-1.272-.162-.373.321-.468.883-.35 1.363-.557-.274-1.295-.094-1.665.408-.37.501-.33 1.267.089 1.727l23.533.41a1.553 1.553 0 0 0-.763-1.467 1.523 1.523 0 0 0-1.637.12 3.17 3.17 0 0 0-.468-1.31c-.079.183-.35.18-.517.07-.166-.108-.284-.284-.457-.381-.4-.224-.885.049-1.26.314-.055-.774-1.144-1.225-1.722-.714-.097-.516-.2-1.048-.496-1.478-.297-.431-.841-.734-1.338-.582-.079.024-.159.059-.24.045-.17-.03-.229-.241-.27-.41A2.432 2.432 0 0 0 12.992.354a2.406 2.406 0 0 0-2.329.7A1.66 1.66 0 0 0 9.186 0h-.032a1.66 1.66 0 0 0-1.49.99"
            id="w"
          />
          <path id="z" d="M0 .321v.303L280.56.302 280.558 0z" />
          <path id="B" d="M0 0h24.596v5.76H0z" />
          <path id="D" d="M0 0h17.024v17.013H0z" />
          <linearGradient x1="-39.453%" y1="44.184%" x2="146.91%" y2="56.301%" id="d">
            <stop stopColor="#00998E" offset="0%" />
            <stop stopColor="#21C9BC" offset="100%" />
          </linearGradient>
          <linearGradient x1="-39.453%" y1="35.638%" x2="146.91%" y2="65.559%" id="f">
            <stop stopColor="#00998E" offset="0%" />
            <stop stopColor="#21C9BC" offset="100%" />
          </linearGradient>
          <linearGradient x1="0%" y1="49.985%" x2="100%" y2="49.985%" id="j">
            <stop stopColor="#67D1FF" offset="0%" />
            <stop stopColor="#2187D8" offset="100%" />
          </linearGradient>
          <linearGradient x1=".001%" y1="50.016%" x2="100%" y2="50.016%" id="o">
            <stop stopColor="#67D1FF" offset="0%" />
            <stop stopColor="#2187D8" offset="100%" />
          </linearGradient>
          <linearGradient x1="64.941%" y1="57.733%" x2="-1.142%" y2="42.776%" id="r">
            <stop stopColor="#AEC8E8" offset="0%" />
            <stop stopColor="#F2F2F2" offset="100%" />
          </linearGradient>
          <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="u">
            <stop stopColor="#AEC8E8" offset="0%" />
            <stop stopColor="#F2F2F2" offset="100%" />
          </linearGradient>
          <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="x">
            <stop stopColor="#AEC8E8" offset="0%" />
            <stop stopColor="#F2F2F2" offset="100%" />
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(-23 -1.65)">
            <mask id="b" fill="#fff">
              <use xmlnsXlink="#a" />
            </mask>
            <path
              d="M311.295 65.155c-1.817-1.112-4.048-1.618-5.57-3.09-3.253-3.148-1.523-8.387-1.228-12.839.597-9.015-6.843-17.847-16.056-19.058-2.107-.277-4.299-.211-6.283-.955-5.538-2.08-6.781-8.974-9.969-13.842-4-6.108-11.962-9.282-19.351-8.352-7.39.93-14.006 5.629-17.839 11.838l-.112.212c-5.008 4.683-13.318 5.593-19.274 2.111-5.8-3.392-8.838-9.979-14.207-13.985-5.22-3.895-12.265-4.965-18.766-3.968-6.5.998-12.543 3.88-18.14 7.244-2.58 1.552-5.105 3.221-7.888 4.396-8.989 3.797-20.296 1.606-27.098-5.252-1.622-1.635-3.013-3.509-4.87-4.889-4.735-3.518-11.514-3.074-17.072-.98-5.558 2.093-10.439 5.594-15.88 7.961-5.44 2.367-11.998 3.492-17.23.717-3.32-1.76-5.78-4.924-9.325-6.203-3.978-1.437-8.71-.05-11.636 2.938-2.925 2.988-4.133 7.328-3.752 11.435.268 2.888 1.258 5.695 1.319 8.594.127 6.01-4.237 11.849-10.158 13.59-5.065 1.49-10.787.21-15.616 2.31-5.72 2.488-8.482 9.295-7.644 15.33.838 6.034 4.577 11.334 8.877 15.764l288.03.63c2.092-3.927.589-9.3-3.262-11.657"
              fill="#EDF1F4"
              mask="url(#b)"
            />
          </g>
          <g fill="#FFF">
            <path d="M38.159 73.702V51.858h3.51V42.81h4.306v9.205h1.063v-4.056h2.978v4.005h4.2v7.697h2.765v-2.913l3.562-3.484v3.432h2.127v-9.881h4.413v26.888z" />
            <path d="M10.246 73.702V51.858h3.51V42.81h4.306v9.205h1.064v-4.056h2.977v4.005h4.2v7.697h2.765v-2.913l3.563-3.484v3.432h2.126v-9.881h4.413v26.888z" />
            <path d="M-17.667 73.702V51.858h3.51V42.81h4.306v9.205h1.064v-4.056h2.977v4.005h4.2v7.697h2.765v-2.913l3.563-3.484v3.432h2.127v-9.881h4.413v26.888zM66.102 73.702V51.858h3.51V42.81h4.306v9.205h1.064v-4.056h2.977v4.005h4.2v7.697h2.765v-2.913l3.563-3.484v3.432h2.126v-9.881h4.414v26.888z" />
            <path d="M94.076 73.702V51.858h3.509V42.81h4.307v9.205h1.063v-4.056h2.978v4.005h4.2v7.697h2.765v-2.913l3.562-3.484v3.432h2.127v-9.881H123v26.888z" />
          </g>
          <path
            d="M88.592 41.939c.087-.318.134-.652.134-.996 0-2.163-1.84-3.917-4.11-3.917-2.271 0-4.112 1.754-4.112 3.917 0 .344.047.678.135.996v.002c.107.385.273.746.488 1.074.739 1.552 1.735 3.328 3.489 6.05 1.591-2.596 2.864-4.764 3.54-6.132.19-.306.337-.638.435-.99l.001-.004"
            fill="#FFDE17"
          />
          <path
            d="M86.67 41.039c0 1.108-.92 2.006-2.054 2.006-1.136 0-2.056-.898-2.056-2.006 0-1.108.92-2.007 2.056-2.007 1.135 0 2.055.899 2.055 2.007"
            fill="#FFF"
          />
          <g fill="#FFF">
            <path d="M172.159 73.702V51.856h3.51v-9.05h4.306v9.206h1.063v-4.057h2.978v4.005h4.2v7.698h2.765v-2.913l3.562-3.485v3.433h2.127v-9.882h4.413V73.7z" />
            <path d="M144.246 73.702V51.856h3.51v-9.05h4.306v9.206h1.063v-4.057h2.978v4.005h4.2v7.698h2.765v-2.913l3.563-3.485v3.433h2.126v-9.882h4.413V73.7z" />
            <path d="M116.333 73.702V51.856h3.51v-9.05h4.306v9.206h1.064v-4.057h2.977v4.005h4.2v7.698h2.765v-2.913l3.563-3.485v3.433h2.127v-9.882h4.413V73.7zM200.102 73.702V51.856h3.51v-9.05h4.306v9.206h1.064v-4.057h2.977v4.005h4.2v7.698h2.765v-2.913l3.563-3.485v3.433h2.126v-9.882h4.413V73.7z" />
            <path d="M228.076 73.702V51.856h3.509v-9.05h4.307v9.206h1.063v-4.057h2.978v4.005h4.2v7.698h2.765v-2.913l3.562-3.485v3.433h2.127v-9.882H257V73.7z" />
          </g>
          <path
            d="M137.93 42.727a3.55 3.55 0 0 0 .113-.885c0-1.923-1.534-3.483-3.426-3.483-1.892 0-3.426 1.56-3.426 3.483 0 .306.04.602.112.885v.002c.09.342.228.663.407.955.616 1.38 1.445 2.959 2.907 5.378 1.326-2.307 2.387-4.235 2.95-5.451.158-.272.282-.567.363-.88v-.004"
            fill="#FFDE17"
          />
          <path
            d="M135.987 42.038c0 .924-.767 1.673-1.713 1.673-.946 0-1.713-.75-1.713-1.673s.767-1.672 1.713-1.672c.946 0 1.713.749 1.713 1.672"
            fill="#FFF"
          />
          <path
            d="M166.708 49.416a3.55 3.55 0 0 0 .112-.885c0-1.924-1.534-3.482-3.426-3.482-1.892 0-3.426 1.558-3.426 3.482 0 .306.04.603.112.885v.003c.089.341.228.662.407.954.615 1.38 1.446 2.96 2.907 5.378 1.326-2.307 2.387-4.234 2.95-5.45.158-.273.281-.568.363-.881v-.004"
            fill="#FFDE17"
          />
          <path
            d="M164.764 48.728c0 .923-.767 1.672-1.713 1.672-.946 0-1.713-.749-1.713-1.672 0-.924.767-1.673 1.713-1.673.946 0 1.713.75 1.713 1.673"
            fill="#FFF"
          />
          <path d="M219 73.035h-8.667V37.409L219 29.024v44.01z" fill="#E7EEFE" />
          <path
            fill="#FFF"
            d="M212.333 39.693h1.334v-2h-1.334zM215.667 39.693H217v-2h-1.333zM213.667 43.523l-1.334.171v-2.497l1.334-.17zM215.667 43.694H217v-2h-1.333zM213.667 47.524l-1.334.171v-2.497l1.334-.17zM215.667 47.695H217v-2h-1.333zM212.333 51.03h1.334v-2.001h-1.334z"
          />
          <path fill="#E7EEFE" d="M229.667 73.035H237V39.693h-7.333z" />
          <path
            fill="#FFF"
            d="M231.667 46.361H233v-2h-1.333zM234.333 46.361h1.334v-2h-1.334zM231.667 50.362H233v-2h-1.333zM234.333 50.362h1.334v-2h-1.334zM231.667 53.697H233v-2h-1.333zM234.333 53.697h1.334v-2h-1.334zM231.667 57.03H233v-2h-1.333zM234.333 57.03h1.334v-2h-1.334z"
          />
          <path d="M245 73.035h-8V37.693c4.418 0 8 3.523 8 7.87v27.472z" fill="#00998E" />
          <path
            fill="#FFF"
            d="M239 49.029h1.333v-2H239zM242.333 49.029h1.334v-2h-1.334zM239 53.03h1.333v-2H239zM242.333 53.03h1.334v-2h-1.334zM239 57.03h1.333v-2H239zM242.333 57.03h1.334v-2h-1.334zM239 61.032h1.333v-2H239zM242.333 61.032h1.334v-2h-1.334z"
          />
          <path fill="#00998E" d="M201 73.035h9.333V43.694H201z" />
          <path
            fill="#FFF"
            d="M203 49.696h1.333v-2.001H203zM206.333 49.696h1.334v-2.001h-1.334zM203 53.697h1.333v-2H203zM206.333 53.697h1.334v-2h-1.334zM203 57.698h1.333v-2H203zM206.333 57.698h1.334v-2h-1.334zM203 62.365h1.333v-2H203zM206.333 62.365h1.334v-2h-1.334z"
          />
          <path
            d="M228.859 43.135a4.336 4.336 0 0 0-4.192-5.442 4.336 4.336 0 0 0-4.192 5.442v.003c.113.426.288.826.515 1.19.778 1.719 1.828 3.686 3.677 6.701 1.678-2.875 3.02-5.277 3.732-6.792.2-.34.355-.708.458-1.097l.002-.005"
            fill="#FFDE17"
          />
          <path d="M227 41.694a2 2 0 1 1-4 0 2 2 0 0 1 4 0" fill="#FFF" />
          <g>
            <g transform="translate(212.347 53.762)">
              <mask id="e" fill="#fff">
                <use xmlnsXlink="#c" />
              </mask>
              <path
                fill="url(#d)"
                mask="url(#e)"
                d="M5.262 4.411 0 8.821h21.046l-5.261-4.41L10.523 0z"
              />
            </g>
            <path
              fill="url(#f)"
              d="M2.133 19.582h16.813V8.508H2.133z"
              transform="translate(212.347 53.762)"
            />
            <path
              fill="#FFF"
              d="M223.336 60.013h1.04v-1.18h-1.04zM221.956 61.465h1.04v-1.18h-1.04zM223.336 61.465h1.04v-1.18h-1.04zM221.192 73.598h4.313v-7.471h-4.313z"
            />
          </g>
          <g fill="#FFF">
            <path d="M221.966 59.592h1V58.49h-1z" />
          </g>
          <g transform="translate(11 73.77)">
            <mask id="h" fill="#fff">
              <use xmlnsXlink="#g" />
            </mask>
            <path d="M1.304.005.006.048H0L1.298.005h.006" fill="#858585" mask="url(#h)" />
          </g>
          <g transform="translate(.358 25.137)">
            <mask id="k" fill="#fff">
              <use xmlnsXlink="#i" />
            </mask>
            <path fill="url(#j)" mask="url(#k)" d="m0 .078 7.847.35L9.963.352 2.116 0z" />
          </g>
          <g transform="translate(1.011 25.08)">
            <mask id="m" fill="#fff">
              <use xmlnsXlink="#l" />
            </mask>
            <path
              d="M1.302.005.004.048a.05.05 0 0 0-.006 0L1.296.005h.006"
              fill="#858585"
              mask="url(#m)"
            />
          </g>
          <g transform="translate(1.711 73.813)">
            <mask id="p" fill="#fff">
              <use xmlnsXlink="#n" />
            </mask>
            <path fill="url(#o)" mask="url(#p)" d="m0 .078 8.49.387 2.076-.078L2.077 0z" />
          </g>
          <path
            d="m7.027 25.024.004-.001.601.033c.018 0 .034.143.035.317 0 .166-.011.3-.028.315a.005.005 0 0 1-.003.002l-.602-.032c-.017-.002-.033-.145-.034-.319-.001-.166.01-.3.027-.315"
            fill="#FFF"
          />
          <g>
            <path
              d="M23.289 21.502c-.41 0-.793.111-1.123.303a2.215 2.215 0 0 0-2.557-1.44 3.526 3.526 0 0 0-3.423-2.66 3.526 3.526 0 0 0-3.412 2.62c-.07-.004-.14-.01-.21-.01-.54 0-1.043.146-1.478.397a2.65 2.65 0 0 0-1.394-.397 2.652 2.652 0 0 0-2.658 2.646h18.355a2.239 2.239 0 0 0-2.1-1.46"
              fill="#DBE2F2"
            />
            <path
              d="M59.974 30.157c.41 0 .793.111 1.124.302a2.215 2.215 0 0 1 2.557-1.44 3.526 3.526 0 0 1 3.422-2.66 3.526 3.526 0 0 1 3.412 2.622c.07-.005.14-.011.21-.011.54 0 1.043.146 1.478.397a2.65 2.65 0 0 1 1.395-.397 2.652 2.652 0 0 1 2.657 2.646H57.874a2.239 2.239 0 0 1 2.1-1.46"
              fill="#FFF"
            />
          </g>
          <g transform="translate(91.69 69.043)">
            <mask id="s" fill="#fff">
              <use xmlnsXlink="#q" />
            </mask>
            <path
              d="M.477-3.509c-.134-.298-.608-.262-.815-.009-.207.253-.22.609-.225.937-.162-.227-.514-.263-.741-.104-.228.159-.323.469-.27.742-.115-.216-.446-.24-.631-.08-.186.16-.233.439-.175.678a.684.684 0 0 0-.827.202.7.7 0 0 0 .044.858l11.7.204a.772.772 0 0 0-.38-.729.757.757 0 0 0-.814.06 1.578 1.578 0 0 0-.232-.651c-.04.09-.175.089-.257.035-.083-.054-.141-.142-.228-.19-.198-.111-.44.025-.626.156-.028-.385-.569-.609-.856-.355-.048-.256-.1-.52-.247-.734-.147-.215-.418-.365-.665-.29-.039.012-.079.03-.119.022-.085-.014-.114-.12-.135-.203a1.209 1.209 0 0 0-.852-.864 1.196 1.196 0 0 0-1.158.348.825.825 0 0 0-.735-.525h-.015a.825.825 0 0 0-.741.492"
              fill="url(#r)"
              mask="url(#s)"
            />
          </g>
          <path
            d="M129.322 29.768H110.99a2.647 2.647 0 0 1 3.556-2.48 2.945 2.945 0 0 1 2.973-.573 1.86 1.86 0 0 1 2.49-.311 3.095 3.095 0 0 1 2.55-1.336 3.09 3.09 0 0 1 3.022 2.423c.409-.44.992-.718 1.643-.718 1.235 0 2.237.996 2.237 2.225 0 .271-.051.53-.14.77"
            fill="#FFF"
          />
          <path
            d="M165.018 29.49c.469 0 .908.128 1.286.347a2.537 2.537 0 0 1 2.93-1.648 4.038 4.038 0 0 1 3.92-3.044 4.039 4.039 0 0 1 3.91 3c.08-.006.16-.012.241-.012a3.38 3.38 0 0 1 1.693.454 3.038 3.038 0 0 1 1.597-.454 3.036 3.036 0 0 1 3.045 3.028h-21.028a2.565 2.565 0 0 1 2.406-1.67"
            fill="#C7ECEE"
          />
          <g>
            <path
              d="M99.755 70.443c.221-.372.307-.848.12-1.238-.188-.389-.697-.627-1.085-.433.1-1.12.02-2.257-.236-3.352-.141-.602-.36-1.229-.852-1.608-.75-.578-1.936-.323-2.544.402-.608.725-.7 1.778-.445 2.687-.668-.184-1.475-.153-1.942.356-.53.579-.396 1.478-.23 2.243.141.648.324 1.36.874 1.734-.668.076-1.47.257-1.675.893-.117.362.015.762.227 1.079.702 1.051 2.112 1.359 3.38 1.39h.628c.35.034.699-.108 1.015-.24.329-.136.67-.239 1.002-.366.525-.2 1.086-.474 1.334-1.006.264-.564.087-1.341-.473-1.619.342-.266.681-.55.902-.922"
              fill="#DAE3F2"
            />
            <path
              d="M96.292 78.52v-8.904c0-.374-.585-.374-.585 0v8.904c0 .374.585.374.585 0"
              fill="#F8F5FF"
            />
            <path
              d="M96.341 72.462a2.406 2.406 0 0 0 1.32-1.537c.04-.146-.05-.323-.204-.358a.298.298 0 0 0-.36.204 2.53 2.53 0 0 1-.07.21c-.026.066.026-.058-.003.006l-.025.055a2.009 2.009 0 0 1-.198.33c-.01.014-.041.07-.004.006-.018.03-.048.059-.071.085a2.144 2.144 0 0 1-.24.234c-.015.012-.03.03-.047.038.028-.014.035-.026.002-.002-.123.09-.255.166-.395.227-.14.062-.184.276-.105.398.094.145.25.17.4.104M95.9 73.229a6.293 6.293 0 0 1-.514-.137 2.759 2.759 0 0 1-.174-.064c.04.016.005.002-.015-.007a1.719 1.719 0 0 1-.226-.124l-.048-.032c-.026-.018-.014-.012.004.003-.03-.025-.06-.049-.087-.076a1.263 1.263 0 0 1-.08-.083c-.006-.006-.047-.056-.022-.024a1.05 1.05 0 0 1-.077-.123l-.02-.04c-.033-.066.017.056-.006-.014a1.14 1.14 0 0 1-.028-.097c-.004-.016-.013-.038-.011-.055l.004.032a.787.787 0 0 1-.003-.045c-.01-.152-.128-.298-.293-.29-.15.006-.302.127-.292.29.025.425.268.77.607 1.017.326.238.737.338 1.125.43.148.035.324-.047.36-.203.034-.15-.046-.32-.205-.358"
              fill="#F8F5FF"
            />
            <path
              d="M108.328 68.998c-.042-.45-.508-.873-.983-.77-.261.056-.587.232-.749.035.516-.479 1.043-.974 1.334-1.594.29-.62.293-1.405-.186-1.91-.479-.505-1.499-.528-1.87.054a6.749 6.749 0 0 0-.012-3.447c-.151-.562-.391-1.128-.853-1.515-.462-.388-1.192-.546-1.726-.241-.306.174-.508.47-.655.773-.5 1.028-.482 2.2-.402 3.323-.367-.454-1.215-.322-1.575.141-.36.464-.326 1.101-.136 1.641s.515 1.032.71 1.57c-.298-.295-.88-.152-1.125.186-.244.338-.233.78-.158 1.18.184.981.7 1.904 1.458 2.605-.356-.32-1-.18-1.308.183-.309.364-.35.864-.299 1.322.203 1.777 1.497 3.476 4.554 3.32 1.19-.133 2.357-.756 2.904-1.75.547-.993.338-2.348-.614-3.02.356-.311.773-.559 1.117-.881.343-.323.617-.754.574-1.205"
              fill="#DAE3F2"
            />
            <path
              d="M104.014 78.719V64.954c0-.375-.585-.375-.585 0v13.765c0 .374.585.375.585 0"
              fill="#F8F5FF"
            />
            <path
              d="M103.943 70.302c1.075-.374 1.927-1.278 2.208-2.38a.296.296 0 0 0-.204-.358.296.296 0 0 0-.36.203 2.825 2.825 0 0 1-.907 1.455 2.797 2.797 0 0 1-.893.519c-.149.052-.248.199-.204.358.04.144.21.255.36.203M103.497 72.577c-.401-.059-.693-.38-.888-.723a2.008 2.008 0 0 1-.254-1.165c.012-.157-.143-.29-.293-.29-.168 0-.28.133-.292.29a2.58 2.58 0 0 0 .351 1.489c.26.441.69.882 1.22.96.156.024.314-.038.36-.203.038-.137-.047-.334-.204-.358M103.91 66.766a4.122 4.122 0 0 1-.626-.626 6.459 6.459 0 0 1-.054-.07 4.075 4.075 0 0 1-.443-.765c-.009-.02-.014-.034-.003-.005a5 5 0 0 1-.03-.076c-.056-.141-.199-.255-.36-.203-.137.044-.264.206-.204.358a4.378 4.378 0 0 0 1.306 1.799c.118.096.299.124.413 0 .098-.106.127-.31 0-.412"
              fill="#F8F5FF"
            />
          </g>
          <g transform="translate(179.766 65.107)">
            <mask id="v" fill="#fff">
              <use xmlnsXlink="#t" />
            </mask>
            <path
              d="M5.664 2.324c-.27-.6-1.224-.527-1.639-.018-.415.509-.444 1.224-.453 1.884-.324-.456-1.033-.528-1.49-.209-.458.32-.65.943-.541 1.493-.233-.435-.9-.482-1.272-.161-.373.32-.468.883-.35 1.363-.557-.275-1.295-.094-1.665.407-.37.501-.331 1.267.089 1.727l23.533.41a1.553 1.553 0 0 0-.763-1.467 1.523 1.523 0 0 0-1.637.121 3.17 3.17 0 0 0-.468-1.31c-.079.183-.35.179-.517.07-.166-.109-.284-.284-.457-.382-.4-.224-.885.05-1.26.314-.056-.774-1.144-1.225-1.722-.714-.097-.515-.2-1.047-.496-1.478-.297-.43-.841-.734-1.338-.582-.079.024-.159.06-.24.045-.17-.03-.229-.24-.27-.41a2.432 2.432 0 0 0-1.715-1.738 2.406 2.406 0 0 0-2.33.7 1.659 1.659 0 0 0-1.477-1.055h-.032a1.66 1.66 0 0 0-1.49.99"
              fill="url(#u)"
              mask="url(#v)"
            />
          </g>
          <g transform="translate(240.352 65.778)">
            <mask id="y" fill="#fff">
              <use xmlnsXlink="#w" />
            </mask>
            <path
              d="M11.664.99c-.27-.6-1.223-.526-1.639-.017-.415.508-.444 1.224-.453 1.883-.324-.456-1.034-.528-1.49-.208-.458.32-.65.943-.541 1.493-.233-.435-.9-.483-1.272-.162-.373.321-.468.883-.35 1.363-.557-.274-1.295-.094-1.665.408-.37.501-.33 1.267.089 1.727l23.533.41a1.553 1.553 0 0 0-.763-1.467 1.523 1.523 0 0 0-1.637.12 3.17 3.17 0 0 0-.468-1.31c-.079.183-.35.18-.517.07-.166-.108-.284-.284-.457-.381-.4-.224-.885.049-1.26.314-.055-.774-1.144-1.225-1.722-.714-.097-.516-.2-1.048-.496-1.478-.297-.431-.841-.734-1.338-.582-.079.024-.159.059-.24.045-.17-.03-.229-.241-.27-.41A2.432 2.432 0 0 0 16.992.354a2.406 2.406 0 0 0-2.329.7A1.66 1.66 0 0 0 13.186 0h-.032a1.66 1.66 0 0 0-1.49.99"
              fill="url(#x)"
              mask="url(#y)"
            />
          </g>
          <g>
            <path
              d="M198.816 68.131c-.097-.796-.757-1.575-1.542-1.507a3.227 3.227 0 0 0 1.042-2.32c.007-.523-.139-1.084-.54-1.409-.343-.278-.898-.29-1.206 0 .522-.719.715-1.68.5-2.547a3.06 3.06 0 0 0-1.825-2.087c-.5-.194-1.08-.242-1.562-.007-.513.25-.844.778-1.034 1.322-.36 1.028-.31 2.15-.253 3.24a2.484 2.484 0 0 0-1.77.327c-.19.117-.38.264-.446.488a1.495 1.495 0 0 0-.042.443 8.71 8.71 0 0 0 .203 1.848c.063.274.15.544.269.799.063.135.298.67.458.695-.503-.08-.99.299-1.204.768-.214.47-.213 1.009-.185 1.525.046.848.189 1.767.8 2.345.659.625 1.608.676 2.453.785.946.122 1.925.098 2.835-.216 1.122-.387 1.992-1.127 2.503-2.212.331-.703.643-1.484.546-2.28"
              fill="#DAE3F2"
            />
            <path
              d="M194.567 73.292v-6.755c0-.487-.742-.488-.742 0v6.755c0 .486.742.487.742 0"
              fill="#F8F5FF"
            />
            <path
              d="M194.535 70.945a3.86 3.86 0 0 0 2.165-1.177c.134-.144.15-.394 0-.535-.145-.135-.382-.153-.525 0a3.691 3.691 0 0 1-.307.295l-.05.042c-.025.02-.03.023-.002.002a3.716 3.716 0 0 1-.728.432c.033-.015.03-.012-.001 0l-.06.025a3.343 3.343 0 0 1-.69.187c-.192.032-.316.285-.26.465.068.212.252.299.458.264M194.01 68.94c-.003 0-.094.02-.036.009.056-.011-.022.001-.035.002-.047.002-.094 0-.141-.003-.065-.004.014 0 .014.003l-.07-.014a1.257 1.257 0 0 1-.137-.041c-.027-.01-.073-.008-.005 0-.016-.002-.038-.018-.053-.025a1.433 1.433 0 0 1-.127-.071c-.04-.026-.084-.052-.12-.084a1.62 1.62 0 0 1-.058-.055c-.071-.068-.133-.143-.198-.218-.037-.043.05.07-.008-.01a4.412 4.412 0 0 1-.122-.181 7.76 7.76 0 0 1-.307-.55c-.091-.174-.344-.242-.508-.135a.39.39 0 0 0-.133.517c.22.42.454.86.816 1.17.4.342.904.532 1.425.415.19-.043.318-.278.259-.465a.379.379 0 0 0-.457-.264"
              fill="#F8F5FF"
            />
            <path
              d="M270.877 67.75c-.097-.796-.757-1.575-1.541-1.507a3.227 3.227 0 0 0 1.042-2.32c.006-.523-.14-1.084-.54-1.41-.343-.278-.899-.288-1.207 0 .523-.718.716-1.679.5-2.547a3.06 3.06 0 0 0-1.824-2.086c-.5-.194-1.08-.242-1.563-.007-.512.25-.843.777-1.034 1.322-.36 1.027-.309 2.15-.252 3.24a2.484 2.484 0 0 0-1.771.326c-.19.118-.38.265-.446.49a1.494 1.494 0 0 0-.042.442c.008.617.064 1.246.203 1.847.064.275.15.545.27.8.063.134.297.67.457.695-.502-.08-.99.299-1.204.768-.214.47-.212 1.008-.184 1.525.046.848.188 1.767.799 2.345.66.624 1.608.675 2.454.785.946.122 1.925.098 2.835-.216 1.121-.387 1.991-1.128 2.502-2.212.332-.703.643-1.484.546-2.28"
              fill="#DAE3F2"
            />
            <path
              d="M266.628 72.91v-6.755c0-.486-.742-.487-.742 0v6.755c0 .487.742.488.742 0"
              fill="#F8F5FF"
            />
            <path
              d="M266.596 70.564a3.86 3.86 0 0 0 2.166-1.178c.133-.143.15-.394 0-.534-.145-.136-.383-.154-.525 0a3.613 3.613 0 0 1-.307.295l-.05.041c-.026.021-.031.024-.002.002a3.736 3.736 0 0 1-.728.433c.032-.015.03-.012-.002 0l-.06.024a3.4 3.4 0 0 1-.69.188c-.191.032-.316.285-.259.465.068.212.251.299.457.264M266.07 68.559c-.002 0-.092.02-.034.009.056-.012-.023 0-.036.002-.047.002-.094 0-.14-.003-.065-.004.014 0 .014.002l-.07-.014a1.25 1.25 0 0 1-.137-.04c-.028-.01-.074-.009-.006 0-.016-.002-.038-.018-.052-.025a1.419 1.419 0 0 1-.127-.072c-.04-.025-.085-.051-.12-.083a1.734 1.734 0 0 1-.058-.055c-.072-.068-.134-.144-.198-.219-.038-.043.05.07-.008-.01l-.045-.063a3.332 3.332 0 0 1-.078-.118 7.696 7.696 0 0 1-.306-.549c-.092-.174-.344-.243-.508-.135a.39.39 0 0 0-.133.517c.219.419.454.86.816 1.17.399.342.903.531 1.424.415.19-.043.319-.278.26-.465a.379.379 0 0 0-.457-.264"
              fill="#F8F5FF"
            />
          </g>
          <g transform="translate(-8.928 73.078)">
            <mask id="A" fill="#fff">
              <use xmlnsXlink="#z" />
            </mask>
            <path fill="#00998E" mask="url(#A)" d="M0 .321v.303L280.56.302 280.558 0z" />
          </g>
          <path fill="#01645C" d="M-2 72h280v6H-2z" />
          <g>
            <g transform="matrix(-1 0 0 1 34.596 10)">
              <mask id="C" fill="#fff">
                <use xmlnsXlink="#B" />
              </mask>
              <path
                d="m1.626 4.605.047.001c.248-1.435 1.472-2.525 2.944-2.525 1.214 0 2.258.742 2.727 1.806a3.126 3.126 0 0 1 2.735-.689C10.828 1.322 12.627 0 14.728 0c2.084 0 3.871 1.3 4.632 3.152.14-.02.282-.03.427-.03 1.146 0 2.15.62 2.71 1.55.15-.043.31-.067.474-.067.744 0 1.379.48 1.625 1.155H0a1.738 1.738 0 0 1 1.626-1.155"
                fill="#C7ECEE"
                mask="url(#C)"
              />
            </g>
            <path
              d="M59.1 18.396c.479 0 .933.104 1.344.288.72-1.62 2.315-2.75 4.172-2.75 1.868 0 3.472 1.143 4.185 2.779.427-.201.9-.317 1.401-.317 1.433 0 2.652.92 3.13 2.21H55.97c.478-1.29 1.697-2.21 3.13-2.21M38.104 27.036c.331 0 .645.074.93.206.498-1.157 1.602-1.964 2.889-1.964 1.293 0 2.403.816 2.897 1.984a2.21 2.21 0 0 1 .97-.226c.992 0 1.836.657 2.167 1.579h-12.02c.33-.922 1.175-1.579 2.167-1.579"
              fill="#C7ECEE"
            />
            <path
              d="M14.568 59.314a2.002 2.002 0 1 0 4.009 0 2.002 2.002 0 0 0-4.009 0"
              fill="#F9CA24"
            />
            <path d="M19.91 57.838s-1.132-1.861-3.339-1.861v6.006l3.34-4.145z" fill="#22A6B3" />
            <path
              d="M16.237 62.65c.185 0 .334-.197.334-.44v-5.793c0-.243-.149-.44-.334-.44-.184 0-.333.197-.333.44v5.793c0 .243.149.44.333.44"
              fill="#30336B"
            />
            <path
              d="M37.102 61.983s-1.8-3.515-1.257-6.219c.544-2.703 2.095-3.124 2.095-3.124H25.816c-1.266 0-3.902 5.695-3.902 5.695l15.188 3.648z"
              fill="#22A6B3"
            />
            <path
              d="M56.638 69.417c.05 4.792 3.827 8.634 8.437 8.582 4.61-.051 8.307-3.977 8.257-8.768-.05-4.791-3.827-8.634-8.437-8.582-4.61.051-8.306 3.977-8.257 8.768"
              fill="#130F40"
            />
            <path
              d="M60.645 69.367a4.005 4.005 0 0 0 4.05 3.961 4.005 4.005 0 1 0-.086-8.008 4.005 4.005 0 0 0-3.964 4.047"
              fill="#22A6B3"
            />
            <path
              d="M63.316 69.676a1.669 1.669 0 1 0 3.337-.037 1.669 1.669 0 0 0-3.337.037"
              fill="#7ED6DF"
            />
            <path
              d="M64.888 59.982c4.98-.055 9.058 4.039 9.112 9.143l-18.03.2c-.053-5.105 3.94-9.288 8.918-9.343"
              fill="#7ED6DF"
            />
            <path d="M55.97 69.324H74v-.147c0-.287-.287-.52-.64-.52H55.97v.667z" fill="#30336B" />
            <g transform="matrix(-1 0 0 1 33.709 60.987)">
              <mask id="E" fill="#fff">
                <use xmlnsXlink="#D" />
              </mask>
              <path
                d="M16.7 10.826c-1.282 4.52-5.988 7.144-10.509 5.863C1.67 15.408-.957 10.706.325 6.187 1.607 1.668 6.31-.956 10.833.325a8.504 8.504 0 0 1 5.866 10.501"
                fill="#130F40"
                mask="url(#E)"
              />
            </g>
            <path
              d="M21.399 70.416a4.006 4.006 0 0 0 7.707-2.184 4.006 4.006 0 0 0-7.707 2.184"
              fill="#22A6B3"
            />
            <path
              d="M23.98 70.113a1.67 1.67 0 0 0 3.212-.91 1.67 1.67 0 0 0-3.211.91"
              fill="#7ED6DF"
            />
            <path
              d="M44.229 71.907c-5.11 0-5.369-4.005-5.369-7.04 0-4.867-3.559-9.557-12.416-9.557s-11.208 8.925-11.208 13.463H34.26v3.888s15.079-.754 9.969-.754M58.695 45.883s-1.292 1.846.1 5.483c1.022 2.668 2.217 3.085 3.192 4.048 1.772 1.751 2.664 4.478 2.664 4.478s-5.967.415-7.913 5.186c-1.946 4.772-1.245 7.583-1.245 7.583h-6.2s4.432-3.593 6.361-8.067c1.93-4.473 1.142-12.797-.59-16.18-1.732-3.385.73-9.121 3.73-9.121l-.099 6.59z"
              fill="#7ED6DF"
            />
            <path
              d="M58.641 39.42s-.713-.527-2.933.565c-2.22 1.093-6.879 3.573-6.879 3.573s-1.518 1.262.22 2.426c1.738 1.164 8.664 4.04 6.726 15.664-.126.758-.411 1.537-.796 2.305-2.17 4.338-6.72 7.027-11.629 7.059l-9.416.062v1.583l17.715.004s6.175-8.078 6.175-14.225c0-6.148-6.023-15.302.817-19.016"
              fill="#22A6B3"
            />
            <path
              d="M59.309 45.299c.737 0 1.336-.508 1.336-1.135v-3.069c0-.626-.599-1.135-1.336-1.135V45.3z"
              fill="#F9CA24"
            />
            <path
              d="M59.309 45.966c.369 0 .668-.301.668-.674v-5.325a.671.671 0 0 0-.668-.674.67.67 0 0 0-.668.674v5.325c0 .373.3.674.668.674"
              fill="#30336B"
            />
            <path
              d="m48.816 43.964 6.486-3.768c-.417-.835-1.384-1.148-2.16-.697l-3.68 2.137c-.775.45-1.064 1.493-.646 2.328"
              fill="#F6E58D"
            />
            <path
              d="M50.628 43.297a1.335 1.335 0 1 0 2.67-.001 1.335 1.335 0 0 0-2.67 0"
              fill="#30336B"
            />
            <path d="M51.296 43.297a.668.668 0 1 0 1.335 0 .668.668 0 0 0-1.335 0" fill="#7ED6DF" />
            <path
              d="M17 67.322h5.82c.237 0 .43-.15.43-.334 0-.184-.193-.333-.43-.333H17c-.237 0-.429.15-.429.333 0 .185.192.334.43.334"
              fill="#30336B"
            />
            <path
              d="M16.566 56.644h6.689c.735 0 1.33-.582 1.33-1.3v-.736c0-.718-.595-1.3-1.33-1.3h-6.689c-.735 0-1.33.582-1.33 1.3v.736c0 .718.595 1.3 1.33 1.3M25.593 53.307h13.015c0-1.474-1.314-2.669-2.933-2.669H23.917v1.144c0 .842.75 1.525 1.676 1.525"
              fill="#F0932B"
            />
            <path
              d="M35.342 73.996h18.552c.778 0 1.408-.598 1.408-1.335H33.934c0 .737.63 1.335 1.408 1.335M15.975 69.992H34.53c.408 0 .74-.3.74-.668 0-.368-.332-.667-.74-.667H15.975c-.408 0-.74.299-.74.667 0 .369.332.668.74.668"
              fill="#30336B"
            />
            <path
              d="M27.945 23.344s.046.949-.383 1.922-.387 1.194 0 1.194c.138 0 .52-.197.52-.197s-.174.402-.24.817c-.055.344.574.553.792.344.218-.21.444-.64.444-.64s.262.083.612 1.163l.905-4.671-2.65.068z"
              fill="#130F40"
            />
            <path
              d="M35.191 23.032s-.253 1.272-.115 1.674c.138.403.288.654.115.963-.173.308-.472 1.622-.541 2.077-.069.455-.898.636-1.29.636s-.668.9-.668.9-1.774-.095-3.433-.53c0 0 .392-2.406.265-3.698-.127-1.291 1.613-3.336 5.667-2.022"
              fill="#FFC2BD"
            />
            <path
              d="M35.27 25.723v-.445s-1.12.284-1.855.193c-.734-.09-.8-.193-.8-.193s-.141 1.887-.685 2.21c0 0 .801.797 1.614 1.127 0 0 .85.033 1.227-.614.477-.819.498-2.278.498-2.278"
              fill="#DFF9FB"
            />
            <path
              d="m32.455 25.945.143-.154s-.702-.907-.962-1.085c-.239-.164-.374-.067-.374-.067l.216.142.977 1.164zM31.85 27.947l.08-.183s-.958-.293-1.095-.348c-.136-.056-.239-.136-.239-.136s-.03.164.181.27c.212.107 1.073.397 1.073.397"
              fill="#DFF9FB"
            />
            <path
              d="M30.929 28.615c.52 0 .96-.35 1.027-.818l.642-4.521h-3.339l.642 4.521c.066.468.507.818 1.028.818"
              fill="#30336B"
            />
            <path
              d="M30.217 24.61h1.424c.16 0 .29-.093.29-.208v-.25c0-.115-.13-.209-.29-.209h-1.424c-.16 0-.29.094-.29.209v.25c0 .115.13.209.29.209M30.139 25.945h.912c.117 0 .211-.09.211-.2v-.267c0-.11-.094-.2-.211-.2h-.912a.206.206 0 0 0-.212.2v.267c0 .11.095.2.212.2M30.189 27.28H31c.145 0 .262-.095.262-.213v-.242c0-.117-.117-.212-.262-.212h-.811c-.145 0-.262.095-.262.212v.242c0 .118.117.213.262.213"
              fill="#22A6B3"
            />
            <path
              d="M27.924 23.276a4.006 4.006 0 0 1 4.006-4.004 4.006 4.006 0 0 1 4.007 4.004h-8.013z"
              fill="#00998E"
            />
            <path
              d="M37.167 23.276h-5.128c-.112 0-.125-.117-.095-.234.195-.749.587-2.402 2.562-3.103 0 0 .713.592 1.44 2.495 0 0 .797.327 1.272.626.095.059.058.216-.051.216"
              fill="#30336B"
            />
            <path
              d="m34.788 46.173 9.65 7.072a3.75 3.75 0 0 1 1.356 4.1l-3.605 11.98-2.656-.27 2.026-11.653s-10.018-4.944-12.002-5.95c-1.984-1.006-2.852-2.956-1.936-5.486l7.167.207z"
              fill="#01645C"
            />
            <path
              d="M29.043 30.217s.029-1.538.67-1.597c.643-.06 3.399.429 4.084 1.257 0 0 .087.074 0 .34 0 0 1.706 1.686 1.998 3.003.292 1.316.073 3.031-.116 4.421-.19 1.39-.803 3.55-.744 5.428a32.72 32.72 0 0 0 .32 3.565h-7.918s.86-3.89.568-6.286c-.291-2.396-.86-4.85-.568-6.744.291-1.893 1.706-3.387 1.706-3.387"
              fill="#F0932B"
            />
            <path
              d="M48.13 41.323s1.703.109 2.07.075c.365-.034 1.357-.197 2.199-.028.842.169 1.348.429 1.497 1.261.139.779.08 1.156-.228.982-.307-.175-.275-.786-.275-.786s-.347-.796-1.45-.677c-.458.05-.842.718-.801.9.128.581.531.921.722 1.066.19.145.438.464.066.513-.371.05-1.193-.66-1.599-.79-.272-.087-.39-.31-.712-.413-.322-.103-1.662-.373-1.662-.373l.174-1.73z"
              fill="#FFC2BD"
            />
            <path
              d="m41.638 69.992 4.983 2.667-7.345.002s.476-1.878.445-2.637l1.917-.032z"
              fill="#130F40"
            />
            <path fill="#FFC2BD" d="m41.947 69.526-.15.434-1.854.032.073-.668z" />
            <path
              d="M18.353 46.634h9.57V29.95h-9.57c-2.09 0-3.785 1.374-3.785 3.07v10.543c0 1.696 1.695 3.07 3.785 3.07"
              fill="#FFDE17"
            />
            <path
              d="m27.955 43.964.645-.879c.657-.859 1.47-1.724 2.256-2.56 2.637-2.805 5.362-5.705 2.572-8.886-.993-1.133-4.503-1.037-5.196-1.006l.037.917c1.553-.068 3.982.107 4.51.708 2.077 2.37.422 4.46-2.552 7.624-.801.852-1.63 1.735-2.321 2.638l-.65.886.7.558z"
              fill="#30336B"
            />
            <path
              d="m47.28 40.29-8.387-1.586-5.988-6.677s-1.4-1.477-2.915-.266c-1.515 1.212-.204 3.464-.204 3.464l7.476 6.761 10.075 1.31.437-.472.183-1.827-.678-.707z"
              fill="#01645C"
            />
            <path
              stroke="#7ED6DF"
              strokeWidth=".539"
              d="M51.942 57.979h4.028v-6.674h-5.342zM54.968 51.305v6.674M53.633 51.305v6.674M51.63 51.305v5.339M56.638 52.974h-5.342M55.97 54.308h-4.674M56.638 56.311h-4.675M55.97 57.645h-4.007"
            />
          </g>
          <g fillRule="nonzero">
            <path fill="#FCED11" d="M17.333 39.2h6V41h-6z" />
            <path
              d="M22 40.133c0-.248.097-.457.29-.628.194-.17.43-.256.71-.256.275 0 .51.086.706.259.196.173.294.38.294.625 0 .236-.098.44-.294.61-.195.171-.43.257-.706.257-.28 0-.516-.085-.71-.254a.787.787 0 0 1-.29-.613M22.106 35h1.769v3.461h-1.769V35zM16 35h1.697v1.772c.305-.223.588-.384.849-.484.261-.1.563-.15.907-.15.588 0 1.048.18 1.381.542.333.361.5.863.5 1.505V41h-1.685v-2.565a1.13 1.13 0 0 0-.277-.776.875.875 0 0 0-.683-.314c-.344 0-.595.12-.754.361-.159.242-.238.618-.238 1.13V41H16v-6z"
              fill="#01645C"
            />
          </g>
        </g>
      </svg>
    </Box>
  );
}
