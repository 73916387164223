import i18next from 'i18next';
import { get, last } from 'lodash';

export class OrderStatus extends String {
  static OrderStatusKeyName: Record<string, string> = {
    PENDING: 'Pending',
    ACCEPTED: 'Pending',
    IN_PROGRESS: 'Order is preparing',
    READY: 'Ready',
    ON_DELIVERY: 'On way',
    DELIVERED: 'Arrived at customer',
    FINISHED: 'Done',
    EXPIRED: 'Expired',
    REJECTED_BY_BRANCH: 'Cancelled by shop',
    CANCELLED_BY_CUSTOMER: 'Cancelled by customer',
    CANCELLED_BY_OPERATOR: 'Cancelled by haader'
  };

  public key: string;

  public displayName: string;

  constructor(order: any) {
    super();

    this.key = get(last(order?.status), 'key');
    this.displayName = OrderStatus.OrderStatusKeyName[this.key] || '';
  }

  get isInProgress() {
    return this.key === 'IN_PROGRESS';
  }

  get isReady() {
    return this.key === 'READY';
  }

  get isDelivered() {
    return this.key === 'DELIVERED';
  }

  get isFinished() {
    return this.key === 'FINISHED';
  }

  get isExpired() {
    return this.key === 'EXPIRED';
  }

  toString() {
    // return i18next.t(`orders.statuses.${this.key.toLowerCase()}`, );
    return this.displayName;
  }
}
