import { QueryFunctionContext } from 'react-query';
import apiInstance from '../apis/api';
import { Item } from '../types/menu.types';

interface GetBrandItemsResponse {
  items: Item[];
}

interface GetItemDetailsResponse {
  item: Item;
}

export function getBrandItems(
  ctx: QueryFunctionContext<[string, { brandId?: string }]>
): Promise<GetBrandItemsResponse> {
  const [, { brandId }] = ctx.queryKey;
  if (!brandId) {
    return Promise.resolve({
      items: [] as Item[]
    });
  }
  return apiInstance.get(`/brands/${brandId}/fetch-items`).then((response) => response.data.data);
}

export function addItem(data: { sectionId: string; item: any }) {
  const { sectionId, item } = data;
  return apiInstance
    .post(`/sections/${sectionId}/add-items`, item)
    .then((response) => response.data);
}

export function deleteItem(data: { itemId: string }) {
  const { itemId } = data;
  return apiInstance.delete(`items/${itemId}`).then((response) => response.data);
}

export function updateItem(data: { sectionId: string; itemId: string; values: any }) {
  const { sectionId, itemId, values } = data;
  return apiInstance
    .put(`/sections/${sectionId}/${itemId}/update-item`, {
      ...values
    })
    .then((response) => response.data);
}

export function updateItemsOrder(data: { sectionId: string; items: any }) {
  const { sectionId, items } = data;
  return apiInstance
    .put(`/sections/${sectionId}/items-order`, {
      items
    })
    .then((response) => response.data);
}

export function getItemDetails(
  ctx: QueryFunctionContext<[string, { brandId?: string; itemId?: string }]>
): Promise<GetItemDetailsResponse | undefined> {
  const [, { brandId, itemId }] = ctx.queryKey;
  if (itemId == null) {
    return Promise.resolve(undefined);
  }
  return apiInstance
    .get(`/items/${itemId}`, { params: { brandId } })
    .then((response) => response.data.data);
}

export function updateItemExtras(data: { itemId: any; itemExtraId: any; values: any }) {
  const { itemId, itemExtraId, values } = data;
  return apiInstance
    .put(`/items/${itemId}/extras/${itemExtraId}`, {
      ...values
    })
    .then((response) => response.data);
}

export function deleteItemExtras(data: { itemId: string; itemExtraId: string }) {
  const { itemId, itemExtraId } = data;
  return apiInstance
    .delete(`/items/${itemId}/extras/${itemExtraId}/delete-extra`)
    .then((response) => response.data);
}
