import { Drawer } from '@mui/material';

const DRAWER_WIDTH = 460;

interface IViewOrderDetailsProps {
  open: boolean;
  handleDrawerClose: () => void;
  children: React.ReactNode;
}

export default function ViewOrderDetails({
  open,
  handleDrawerClose,
  children
}: IViewOrderDetailsProps) {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleDrawerClose}
      ModalProps={{ keepMounted: true }}
      PaperProps={{ sx: { pb: 5, width: DRAWER_WIDTH } }}
    >
      {children}
    </Drawer>
  );
}
