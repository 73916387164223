import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import BrandDetails from '../ordersList/BrandDetails';
import ContactsDetails from '../ordersList/ContactsDetails';
import OrderDetailsHeader from '../ordersList/OrderDetailsHeader';
import OrderItemsDetails from '../ordersList/OrderItemsDetails';

interface OrderOverviewProps {
  orderDetails: any;
  closeDialog: () => void;
  orderId: string;
  acceptNewOrder: (id: string) => void;
  rejectNewOrder: (id: string) => void;
}

export default function OrderOverview({
  orderDetails,
  closeDialog,
  rejectNewOrder,
  orderId,
  acceptNewOrder
}: OrderOverviewProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { _id, orderNo, createdAt, ...rest } = orderDetails;

  return (
    <>
      <Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
          <IconButton aria-label="close" onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ fontWeight: 'bold', color: 'crimson', fontSize: 14, cursor: 'pointer' }}
            onClick={() => rejectNewOrder(orderId)}
          >
            {t('app.reject')}
          </Typography>
        </Stack>
        <OrderDetailsHeader id={_id} orderNo={orderNo} createdAt={createdAt} />
        <Divider />
        <BrandDetails orderDetails={orderDetails} />
        <Divider />
        <ContactsDetails orderDetails={orderDetails} target="new" />
        <OrderItemsDetails order={orderDetails} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          boxShadow: theme.customShadows.z24,
          background: '#fff',
          padding: 2,
          width: '100%'
        }}
      >
        <Button
          variant="contained"
          sx={{ backgroundColor: '#01645c', color: '#fff', padding: 1.5, width: '100%' }}
          onClick={() => acceptNewOrder(orderId)}
        >
          <Typography variant="body2">{t('app.accept')}</Typography>
        </Button>
      </Box>
    </>
  );
}
