function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login')
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    // app: path(ROOTS_DASHBOARD, '/app'),
    users: path(ROOTS_DASHBOARD, '/users'),
    brands: path(ROOTS_DASHBOARD, '/brands'),
    menu: path(ROOTS_DASHBOARD, '/brands/:brandId'),
    section: path(ROOTS_DASHBOARD, '/brands/:brandId/sections/:sectionId'),
    createItem: path(ROOTS_DASHBOARD, '/brands/:brandId/sections/:sectionId/items/create'),
    editItem: path(ROOTS_DASHBOARD, '/brands/:brandId/sections/:sectionId/items/:itemId/edit'),
    item: path(ROOTS_DASHBOARD, '/brands/:brandId/sections/:sectionId/items/:itemId'),
    orders: path(ROOTS_DASHBOARD, '/orders-overview'),
    orderStatus: path(ROOTS_DASHBOARD, '/order-status'),
    branchStatus: path(ROOTS_DASHBOARD, '/branch-status'),
    // branch: path(ROOTS_DASHBOARD, '/branch'),
    // reports: path(ROOTS_DASHBOARD, '/reports'),
    workingHours: path(ROOTS_DASHBOARD, '/working-hours'),
    recentOrders: path(ROOTS_DASHBOARD, '/orders-history'),
    itemAvailability: path(ROOTS_DASHBOARD, '/item-availability')
    // performance: path(ROOTS_DASHBOARD, '/recent_orders'),
    // item: path(ROOTS_DASHBOARD, '/item')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  }
};

// export const PATH_DASHBOARD = {
//   root: ROOTS_DASHBOARD,
//   general: {
//     app: path(ROOTS_DASHBOARD, '/app'),
//     brands: path(ROOTS_DASHBOARD, '/menu/brands'),
//     menu: path(ROOTS_DASHBOARD, '/menu/view'),
//     orders: path(ROOTS_DASHBOARD, '/orders-overview'),
//     orderStatus: path(ROOTS_DASHBOARD, '/order-status'),
//     branch: path(ROOTS_DASHBOARD, '/branch'),
//     reports: path(ROOTS_DASHBOARD, '/reports'),
//     openingTimes: path(ROOTS_DASHBOARD, '/opening-times'),
//     recentOrders: path(ROOTS_DASHBOARD, '/orders-history'),
//     // performance: path(ROOTS_DASHBOARD, '/recent_orders'),
//     item: path(ROOTS_DASHBOARD, '/item')
//   },
//   user: {
//     root: path(ROOTS_DASHBOARD, '/user'),
//     profile: path(ROOTS_DASHBOARD, '/user/profile'),
//     account: path(ROOTS_DASHBOARD, '/user/account')
//   }
// };
