import SvgIconStyle from '../../components/SvgIconStyle';
import { PATH_DASHBOARD } from '../../routes/paths';

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  calendar: getIcon('ic_calendar')
};

const sidebarConfig = [
  {
    subheader: '',
    items: [
      // {
      //   title: 'app',
      //   path: PATH_DASHBOARD.general.app,
      //   icon: ICONS.dashboard
      // },
      { title: 'users', path: PATH_DASHBOARD.general.users, icon: ICONS.user },
      { title: 'menu', path: PATH_DASHBOARD.general.brands, icon: ICONS.ecommerce },
      // { title: 'reports', path: PATH_DASHBOARD.general.reports, icon: ICONS.ecommerce },
      { title: 'recentOrders', path: PATH_DASHBOARD.general.recentOrders, icon: ICONS.ecommerce },
      {
        title: 'itemAvailability',
        path: PATH_DASHBOARD.general.itemAvailability,
        icon: ICONS.ecommerce
      },
      { title: 'orderStatus', path: PATH_DASHBOARD.general.orderStatus, icon: ICONS.ecommerce },
      { title: 'openingTimes', path: PATH_DASHBOARD.general.workingHours, icon: ICONS.calendar },
      { title: 'branchStatus', path: PATH_DASHBOARD.general.branchStatus, icon: ICONS.ecommerce }
    ]
  }
];

export default sidebarConfig;
