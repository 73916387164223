import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Page from '../../../components/Page';
import LoginForm from './LoginForm';
import Logo from '../../../components/Logo';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    background: '#eee'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: '0 auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const LogoStyle = styled('div')(({ theme }) => ({
  width: '75%',
  margin: '0 auto'
}));

export default function Login() {
  const { t } = useTranslation();

  return (
    <RootStyle title="Login | Haader App">
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5, justifyContent: 'center', alignItems: 'center' }}>
            <LogoStyle>
              <Logo sx={{ width: '50%', margin: '0px auto' }} />
            </LogoStyle>
            <Typography variant="subtitle1" gutterBottom sx={{ textAlign: 'center' }}>
              { t('app.loginPhrase') }
            </Typography>
          </Stack>
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
