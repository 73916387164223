import { useCallback, useState } from 'react';
import TransitionsDialogs from '../pages/menu/Dialog';

interface UseTransitionDialogProps {
  title: string;
  message: string;
  onConfirm: () => void;
}

export function useDeleteDialog({ title, message, onConfirm }: UseTransitionDialogProps) {
  const [open, setOpen] = useState(false);

  const openDeleteDialog = useCallback(() => setOpen(true), []);
  const closeDeleteDialog = useCallback(() => setOpen(false), []);

  return {
    openDeleteDialog,
    closeDeleteDialog,
    deleteDialog: (
      <TransitionsDialogs
        size="sm"
        open={open}
        handleClose={closeDeleteDialog}
        handleConfirm={onConfirm}
        title={title}
      >
        {message}
      </TransitionsDialogs>
    )
  };
}
