import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './slices/auth';
import menuReducer from './slices/menu';
import ordersReducer from './slices/orders';
import userReducer from './slices/user';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'store-',
  whitelist: []
};

const menuPersistConfig = {
  key: 'menu',
  storage,
  keyPrefix: 'menu-',
  whitelist: []
};

const authPersistConfig = {
  key: 'auth',
  storage,
  keyPrefix: 'auth-',
  whitelist: [
    'isAuthenticated',
    'user',
    'branches',
    'currentBranch',
    'currentBranchId',
    'currentBrandId',
    'brands'
  ]
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  menu: persistReducer(menuPersistConfig, menuReducer),
  user: userReducer,
  orders: ordersReducer
});

export { rootPersistConfig, rootReducer };
