import { useContext } from 'react';
import { forgetPassword, login, logout, resetPassword } from 'store/slices/auth';
import { RootState, useDispatch, useSelector } from 'store/store';
import { AuthContext } from '../contexts/JWTContext';

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};

export default useAuth;

export const useAuthUser = () => {
  const dispatch = useDispatch();
  const {
    user,
    isLoading,
    isAuthenticated,
    resetTokenSent,
    errors,
    currentRolePermissions,
    isSuperAdmin,
    branches,
    currentBranch,
    currentBrandId
  } = useSelector((state: RootState) => state.auth);

  return {
    user,
    isLoading,
    isAuthenticated,
    resetTokenSent,
    errors,
    currentRolePermissions,
    isSuperAdmin,
    branches,
    currentBranch,
    currentBrandId,

    login: ({ email, password }: { email: string; password: string }) =>
      dispatch(
        login({
          email,
          password
        })
      ),

    logout: () => dispatch(logout()),

    forgetPassword: (email: string) => dispatch(forgetPassword(email)),

    resetPassword: (token: string, passwords: { password: string; confirmPassword: string }) =>
      dispatch(resetPassword(token, passwords))
  };
};
