import { Typography } from '@material-ui/core';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  styled
} from '@mui/material';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { get } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import Counter from '../../../pages/menu/Counter';
import { ItemExtra } from '../../../store/slices/menu';
import { Extras, Item } from '../../../types/menu.types';
import ChoicesList from './ChoicesList';

interface InitialState {
  itemExtraEn: string | undefined;
  itemExtraAr: string | undefined;
  choices: any;
  newChoices: any;
  rules: string | undefined;
  minNumChoice: number | undefined;
  maxNumChoice: number | undefined;
  afterSubmit?: boolean;
  extraOrder: number | undefined;
}

interface ItemExtraFormProps {
  edit: boolean;
  item?: Item | null;
  onSubmit: (value: any) => void;
  onCancel: () => void;
  extraIndex?: number | null;
  extrasList?: Extras[] | null;
}

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

const RULES_OPTION = ['Mandatory', 'Optional'];

export function ItemExtraForm({
  edit,
  extraIndex,
  extrasList,
  item,
  onSubmit,
  onCancel
}: ItemExtraFormProps) {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const [rulesBaseValue, setRulesBaseValue] = useState(0);

  const ItemModifierSchema = Yup.object().shape({
    itemExtraEn: Yup.string().required(t('menu.nameEnValidate')),
    itemExtraAr: Yup.string().required(t('menu.nameArValidate')),
    rules: Yup.string().required(t('menu.rulesValidate')),
    choices: Yup.array().of(Yup.object()),
    newChoices: Yup.array().of(
      Yup.object()
        .shape({
          choiceItemName_en: Yup.string().required(t('menu.nameEnValidate')),
          choiceItemName_ar: Yup.string().required(t('menu.nameArValidate')),
          choiceItemPrice: Yup.string().required(t('menu.priceValidate'))
        })
        .required()
    ),
    maxNumChoice: Yup.number(),
    minNumChoice: Yup.number(),
    extraOrder: Yup.number()
  });

  const onFormSubmit = (
    values: InitialState,
    { setErrors, setSubmitting, resetForm }: FormikHelpers<InitialState>
  ) => {
    const {
      itemExtraEn,
      itemExtraAr,
      rules,
      choices,
      newChoices,
      minNumChoice,
      maxNumChoice,
      extraOrder
    } = values;

    if (rules === RULES_OPTION[0] && choices.length === 0 && newChoices.length === 0) {
      setErrors({ choices: t('menu.errors.mandatoryChoice') });
      setSubmitting(false);
      return;
    }

    const updatedChoices = [
      ...choices,
      ...newChoices.map((choice: any) => ({
        _id: choice._id,
        name: {
          en: choice.choiceItemName_en,
          ar: choice.choiceItemName_ar
        },
        price: choice.choiceItemPrice
      }))
    ];

    const extra = {
      extra: {
        _id: null,
        name: { en: itemExtraEn, ar: itemExtraAr },
        rules,
        min: minNumChoice,
        max: maxNumChoice,
        order: extraOrder
      },
      choices: updatedChoices
    };

    if (edit) {
      // const extrasList = [...(item?.extras ?? [])];
      // @ts-ignore
      extrasList[extraIndex] = extra;
      onSubmit(extrasList);
    } else {
      onSubmit([...(extrasList ?? []), extra]);
    }

    if (isMountedRef.current) {
      setSubmitting(false);
    }

    resetForm();
    onCancel();
  };

  const extra = useMemo<ItemExtra | null>(
    () => get(extrasList, `${extraIndex}`, null),
    [extraIndex, extrasList]
  );
  const extraOrder = useMemo(() => {
    let extraOrder;

    if (edit) {
      extraOrder = get(extrasList, `${extraIndex}.extra.order`);
    } else {
      const extrasLength = get(extrasList, 'length', 0);
      extraOrder = extrasLength > 0 ? extrasLength + 1 : 0;
    }

    return extraOrder;
  }, [edit, extraIndex, extrasList]);

  const formik = useFormik<InitialState>({
    enableReinitialize: true,
    initialValues: {
      itemExtraEn: extra?.extra?.name.en ?? '',
      itemExtraAr: extra?.extra?.name.ar ?? '',
      // @ts-ignore
      rules: extra?.extra?.rules ?? RULES_OPTION[1],
      choices: extra?.choices ?? [],
      newChoices: [],
      maxNumChoice: extra?.extra?.max ?? 1,
      minNumChoice: extra?.extra?.min ?? 0,
      // eslint-disable-next-line no-nested-ternary
      extraOrder
    },

    validationSchema: ItemModifierSchema,
    onSubmit: onFormSubmit
  });

  const { isSubmitting, values, touched, errors, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  const handleRulesChange = (e: any) => {
    if (e.target.value === RULES_OPTION[0]) {
      setFieldValue('rules', RULES_OPTION[0]);
      setFieldValue('minNumChoice', 1);
      setFieldValue('maxNumChoice', 1);
      setRulesBaseValue(1);
    } else {
      setFieldValue('rules', RULES_OPTION[1]);
      setFieldValue('minNumChoice', 0);
      setFieldValue('maxNumChoice', 1);
      setRulesBaseValue(1);
    }
  };

  return (
    <Stack>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Paper
            sx={{
              p: 3,
              my: 2,
              bgcolor: 'background.neutral'
            }}
          >
            <Stack direction={{ xs: 'column', md: 'row' }} sx={{ mb: 1 }} spacing={2}>
              <TextField
                {...getFieldProps('itemExtraEn')}
                fullWidth
                size="small"
                label={t('menu.nameEn')}
                error={Boolean(touched.itemExtraEn && errors.itemExtraEn)}
                helperText={touched.itemExtraEn && errors.itemExtraEn}
              />
              <TextField
                {...getFieldProps('itemExtraAr')}
                fullWidth
                size="small"
                label={t('menu.nameAr')}
                error={Boolean(touched.itemExtraAr && errors.itemExtraAr)}
                helperText={touched.itemExtraAr && errors.itemExtraAr}
              />
            </Stack>
            <Box sx={{ my: 2 }}>
              <LabelStyle>{t('menu.choices')}</LabelStyle>
              {errors.choices ? (
                <Typography my={1} color="red" textAlign="center" sx={{ direction: 'rtl' }}>
                  {errors.choices}
                </Typography>
              ) : null}
              <ChoicesList
                extraChoicesItems={values.choices}
                formik={formik as any}
                setValue={(value: any) =>
                  setFieldValue('choices', [
                    ...values.choices,
                    {
                      _id: value._id ? value._id : null,
                      name: {
                        en: value.choiceItemName_en,
                        ar: value.choiceItemName_ar
                      },
                      price: value.choiceItemPrice
                    }
                  ])
                }
                setFieldUpdate={(value) => {
                  setFieldValue('choices', value);
                }}
              />
            </Box>
            <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
              <Box sx={{ alignSelf: 'flex-end' }}>
                <LabelStyle>{t('menu.rules')}</LabelStyle>
              </Box>
              <RadioGroup {...getFieldProps('rules')} row>
                <Stack spacing={1} direction="row">
                  {RULES_OPTION.map((rule) => (
                    <FormControlLabel
                      key={rule}
                      value={rule}
                      control={<Radio />}
                      defaultChecked={rule === values.rules}
                      label={rule === 'Mandatory' ? t('menu.mandatory') : t('menu.optional')}
                      onChange={handleRulesChange}
                    />
                  ))}
                </Stack>
              </RadioGroup>
            </Stack>
            <Stack direction="row" spacing={{ xs: 2 }}>
              <Box>
                <Typography variant="body2" sx={{ my: 1 }}>
                  {t('menu.minNumChoice')}
                </Typography>
                <Counter
                  name="minNumChoice"
                  minBaseValue={rulesBaseValue}
                  maxBase={values.maxNumChoice}
                />
              </Box>
              <Box>
                <Typography variant="body2" sx={{ my: 1 }}>
                  {t('menu.maxNumChoice')}
                </Typography>
                <Counter
                  name="maxNumChoice"
                  minBaseValue={rulesBaseValue}
                  minBase={values.minNumChoice}
                  setFieldVal={(value) => setFieldValue('minNumChoice', value)}
                />
              </Box>
            </Stack>
          </Paper>
          <Stack>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              {t('app.saveChanges')}
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </Stack>
  );
}
