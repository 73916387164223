import edit2Outline from '@iconify/icons-eva/edit-2-outline';
import moreVerticalOutline from '@iconify/icons-eva/more-vertical-outline';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import { Box, Card, Menu, MenuItem, Stack, Typography } from '@mui/material';
import MAvatar from 'components/@material-extend/MAvatar';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_DASHBOARD } from 'routes/paths';
import { localize } from 'utils/localize';
import { deleteItem, getBrandItems } from '../../../../services/items.service';
import { getBrandSections } from '../../../../services/sections.service';
import { Item } from '../../../../types/menu.types';
import TransitionsDialogs from '../../Dialog';

type MenuItemCardProps = {
  item: Item;
  sectionId: string | null;
  provided: DraggableProvided;
  showEditable?: () => void;
};

const MENU_OPTIONS = ['Edit', 'Delete'];

export default function MenuItemCard({
  item: { _id: itemId, name, description, price, image },
  sectionId,
  provided,
  showEditable
}: MenuItemCardProps) {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { brandId } = params;

  const [isMenuOpen, setMenuOpen] = useState<null | HTMLDivElement>(null);
  const [isConfirm, setConfirmOpen] = useState(false);

  const { refetch: refetchSections } = useQuery(['sections', { brandId }], getBrandSections);
  const { refetch: refetchItems } = useQuery(['items', { brandId }], getBrandItems);
  const deleteItemMutation = useMutation(deleteItem);

  const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement>, id: string) => {
    event.stopPropagation();
    setMenuOpen(event.currentTarget);
  };

  const handleMenuClose = (event?: any, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (event) {
      event.stopPropagation();
    }
    setMenuOpen(null);
  };

  const confirmationBoxOpen = () => {
    setConfirmOpen(true);
  };

  const confirmationBoxClose = () => {
    setConfirmOpen(false);
  };

  const handleUserAction = (e: React.MouseEvent, option: string) => {
    e.stopPropagation();

    if (option === 'Delete') {
      handleMenuClose();
      confirmationBoxOpen();
    }
    if (option === 'Edit') {
      const { brandId } = params;

      handleMenuClose();

      if (showEditable) {
        showEditable();
      }

      navigate(
        PATH_DASHBOARD.general.editItem
          .replace(':brandId', brandId ?? '')
          .replace(':sectionId', sectionId ?? '')
          .replace(':itemId', itemId)
      );
    }
  };

  const userConfirmAction = () => {
    deleteItemMutation.mutate(
      { itemId },
      {
        onSuccess() {
          confirmationBoxClose();
          refetchItems();
          refetchSections();
          enqueueSnackbar('Deleted successfully', { variant: 'success' });
        }
      }
    );
  };

  const navigateToItem = useCallback(() => {
    const { brandId } = params;

    if (brandId == null || sectionId == null) {
      return;
    }

    navigate(
      PATH_DASHBOARD.general.item
        .replace(':brandId', brandId)
        .replace(':sectionId', sectionId)
        .replace(':itemId', itemId)
    );
  }, [itemId, navigate, params, sectionId]);

  return (
    <>
      <TransitionsDialogs
        size="sm"
        open={isConfirm}
        handleClose={confirmationBoxClose}
        handleConfirm={userConfirmAction}
        title={t('app.important')}
      >
        {t('messages.deleteConfirm')}
      </TransitionsDialogs>
      <Card
        sx={{ p: 1, mb: 2, position: 'relative' }}
        ref={provided.innerRef}
        onClick={navigateToItem}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <Stack direction="row" spacing={2}>
          {image && (
            <MAvatar
              variant="rounded"
              alt={localize(name)}
              src={image}
              color="info"
              sx={{ width: 140, height: 120 }}
            />
          )}
          {!image && <Box sx={{ width: 140, height: 120 }}>{t('menu.NoImage')}</Box>}
          <Box>
            <Stack direction="column">
              <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                {localize(name)}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {localize(description)}
              </Typography>
              <Box
                sx={{
                  marginTop: 'auto',
                  position: 'absolute',
                  bottom: '6%',
                  display: 'flex',
                  width: '70%',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <Typography variant="h5" sx={{ color: 'text.secondary', margin: '0 5px' }}>
                    {price}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#01645c', alignSelf: 'center' }}>
                    {t('orders.currency')}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Box
          sx={{
            position: 'absolute',
            top: '10%',
            left: '94%',
            cursor: 'pointer'
          }}
          onClick={(e) => handleMenuOpen(e, itemId)}
        >
          <Icon icon={moreVerticalOutline} width={20} height={20} />
        </Box>
        <Menu
          keepMounted
          id="simple-menu"
          anchorEl={isMenuOpen}
          onClose={handleMenuClose}
          open={Boolean(isMenuOpen)}
        >
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option} onClick={(e) => handleUserAction(e, option)}>
              <Icon
                icon={(option === 'Delete' && trash2Outline) || edit2Outline}
                width={20}
                height={20}
              />
              <Typography sx={{ mx: 1 }}>
                {option === 'Edit' ? t('app.edit') : t('app.delete')}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Card>
    </>
  );
}
