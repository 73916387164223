import axios from 'axios';
import { baseURL } from '../config';

export const uploadPhoto = async (formData: any) => {
  const response = await axios.post(`${baseURL.uploadEndpoint}`, formData, {
    headers: {
      'Content-Type': `multipart/form-data;boundary=${formData._boundary}`
    }
  });

  return response.data.data;
};
