import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Router from './routes';
import ThemeConfig from './theme';
// import useAuth from './hooks/useAuth';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import GlobalStyles from './theme/globalStyles';
import { useNotificationPermission } from './hooks/useNotificationPermission';
import { LiveOrdersHOC } from './HOCs/live-order-hoc';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  }
});

export default function App() {
  const isInitialized = true;

  useNotificationPermission();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <QueryClientProvider client={queryClient}>
              <NotistackProvider>
                <GlobalStyles />
                <ScrollToTop />
                <LiveOrdersHOC>{isInitialized ? <Router /> : <LoadingScreen />}</LiveOrdersHOC>
              </NotistackProvider>
            </QueryClientProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}

export const useAudio = (url: any) => {
  const [audio] = React.useState(new Audio(url));
  const [playing, setPlaying] = React.useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [audio, playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  return [playing, setPlaying, toggle];
};
