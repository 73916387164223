import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Link, Stack, Alert, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { dispatch } from 'store/store';
import { setError } from 'store/slices/auth';
import { PATH_AUTH } from '../../../routes/paths';
import { useAuthUser } from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { MIconButton } from '../../../components/@material-extend';

type InitialValues = {
  email: string;
  password: string;
  afterSubmit?: string;
};
export default function LoginForm() {
  const { login, errors: authErrors } = useAuthUser();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('formValidation.emailValid'))
      .required(t('formValidation.emailRequired')),
    password: Yup.string().required(t('formValidation.passwordRequired'))
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      const { email, password } = values;
      try {
        await login({ email, password });
        // if (Object.values(authErrors).length < 0) {
        //   enqueueSnackbar('Login Failed', {
        //     variant: 'error',
        //     action: (key) => (
        //       <MIconButton size="small" onClick={() => closeSnackbar(key)}>
        //         <Icon icon={closeFill} />
        //       </MIconButton>
        //     )
        //   });
        // } else {
        enqueueSnackbar(t('auth.loginSuccess'), {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        // }
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error: any) {
        console.error(error);
        dispatch(setError(error.response.data));
        enqueueSnackbar(t('auth.loginFailed'), {
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          // setErrors({ afterSubmit: error.message });
        }
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {/* {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>} */}

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label={t('auth.userName')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label={t('auth.password')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="column" spacing={2} sx={{ mt: 2, justifyContent: 'center' }}>
          <Link component={RouterLink} variant="subtitle2" color="infp" to={PATH_AUTH.login}>
            {t('auth.forgetPassword')}
          </Link>

          <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
            {t('auth.login')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
