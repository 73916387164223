import { Dispatch, createSlice } from '@reduxjs/toolkit';
import apiInstance from 'apis/api';
import { set } from 'lodash';
import { pauseAudio, playAudio } from '../../pages/orders/orders';
import { DoneCallback } from '../../types/utils';
import { OrderStatus } from '../../utils/OrderStatus';
import { dispatch, getState } from '../store';
import { TimeUtils } from '../../utils/time-utils';

export type Order = {
  _id: string;
  name: string;
  description: string;
  price: number;
  createdAt: number;
  status: string;
  currentStatus: string;
  orderNo: string;
  orderDetails: any;
};

export type Branch = {
  _id: string;
  brand: string;
  address: {
    en: string;
    ar: string;
  };
  name: {
    en: string;
    ar: string;
  };
  location: {
    type: 'Point';
    coordinates: [number, number];
  };
  active: boolean;
  deletedAt: string | null;
  deleted: boolean;
  shifts: {
    day: string;
    opensAt: string;
    closesAt: string;
    status: boolean;
    _id: string;
  }[];
  updatedAt: string;
  isPremium: boolean;
  isVendor: boolean;
};

type OrderState = {
  isLoading: boolean;
  isLoadingNewOrders: boolean;
  brand: {
    branches: {
      isLoadingEdit: false;
      loading: boolean;
      list: Branch[];
    };
  };
  error: boolean;
  currentOrderdetail: {};
  ordersList: Order[];
  orderStatus: [];
  rejectionReasons: any[];
  totalOrders: number;
  totalRevenu: number;
  totalDiscount: number;
  totalDiscounted: number;
  todayOrders: Order[];
  newOrders: Order[];
  currentOrders: Order[];
  upComingOrders: Order[];
  selectedOrder: Order | null;
};

const initialState: OrderState = {
  isLoading: false,
  isLoadingNewOrders: false,
  brand: {
    branches: {
      isLoadingEdit: false,
      loading: false,
      list: []
    }
  },
  error: false,
  currentOrderdetail: {},
  ordersList: [],
  orderStatus: [],
  rejectionReasons: [],
  totalOrders: 0,
  totalRevenu: 0,
  totalDiscount: 0,
  totalDiscounted: 0,
  todayOrders: [],
  newOrders: [],
  currentOrders: [],
  upComingOrders: [],
  selectedOrder: null
};

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    startLoadingNewOrders(state) {
      state.isLoadingNewOrders = true;
    },

    loadingEditOrderBranch(state, { payload }) {
      state.brand.branches.isLoadingEdit = payload;
    },

    loadingBrandBranches(state, { payload }) {
      state.brand.branches.loading = payload;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.isLoadingNewOrders = false;
      state.error = action.payload;
    },

    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.ordersList = action.payload.orders;
      state.totalOrders = action.payload.total;
      if (action.payload.orders.length === 0) {
        state.totalRevenu = 0;
        state.totalDiscount = 0;
        state.totalDiscounted = 0;
      } else {
        state.totalRevenu = action.payload.totalRevenu.toFixed(2);
        state.totalDiscount = action.payload.totalDiscount.toFixed(2);
        state.totalDiscounted = action.payload.totalDiscounted.toFixed(2);
      }
    },

    getOrderStatusSuccess(state, action) {
      state.orderStatus = action.payload
        .map((status: any) => {
          const statusName = OrderStatus.OrderStatusKeyName[status.key];
          set(status, 'name.en', statusName);
          set(status, 'vendor.en', statusName);
          if (!statusName) {
            return null;
          }
          return status;
        })
        .filter((s: any) => s != null);
    },

    getOrderRejectionReasonsSuccess(state, action) {
      state.rejectionReasons = action.payload;
    },

    getTodayOrdersSuccess(state, action) {
      state.isLoading = false;
      state.todayOrders = action.payload;
    },

    getNewOrdersSuccess(state, action) {
      state.isLoading = false;
      state.isLoadingNewOrders = false;
      state.newOrders = action.payload;
    },

    getUpcomingOrdersSuccess(state, action) {
      state.isLoading = false;
      state.upComingOrders = action.payload;
    },

    getCurrentOrdersSuccess(state, action) {
      state.isLoading = false;
      state.currentOrders = action.payload;
    },

    currentOrderDetailsSuccess(state, action) {
      state.isLoading = false;
      state.currentOrderdetail = action.payload.currentOrderdetail;
    },

    setSelectedOrderSuccess(state, action) {
      state.isLoading = false;
      state.selectedOrder = action.payload;
    },

    updateOrderBranchSuccess(state) {},

    getBrandBranchesSuccess(state, { payload }) {
      state.brand.branches.list = payload;
    },

    handleOrderChanged(state, { payload }) {
      const { order } = payload;
      const { newOrders, currentOrders } = state;

      const status: string = order.currentStatus || '';
      const cancelled = ['CANCELLED_BY_CUSTOMER', 'CANCELLED_BY_OPERATOR'].includes(status);
      const newOrderIndex = newOrders.findIndex((o) => o._id === order._id);
      const currentOrderIndex = currentOrders.findIndex((o) => o._id === order._id);

      if (newOrderIndex !== -1) {
        state.newOrders.splice(newOrderIndex, 1);
        if (!cancelled) {
          state[order.driver ? 'currentOrders' : 'newOrders'].unshift(order);
        }
      }

      if (currentOrderIndex !== -1) {
        state.currentOrders.splice(currentOrderIndex, 1, order);
      }
    }
  }
});

export default slice.reducer;
export const { handleOrderChanged } = slice.actions;

export function getBranchOrders(
  pageNumber: number | string,
  pageSize: number | string,
  date?: any,
  status?: string,
  brandId?: string,
  orderNo?: string,
  customerName?: string,
  branchId?: string
) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      // const branchId: string = getState().auth.currentBranchId;
      const response = await apiInstance.post(`branches/orders/${pageNumber}/${pageSize}`, {
        brandId,
        branchId,
        status,
        orderNo,
        customerName,
        date
      });
      if (response) {
        dispatch(slice.actions.getOrdersSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrderStatusList() {
  return async (dispatch: any, getState: any) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get('orders-status');
      if (response) {
        dispatch(slice.actions.getOrderStatusSuccess(response.data.data.statusList));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getOrderRejectionReasons() {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await apiInstance.get('/orders/rejectReasons');
      if (response) {
        dispatch(slice.actions.getOrderRejectionReasonsSuccess(response.data.data.rejectReasons));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getNewOrders(options?: { withSound?: boolean; isCallCenter?: boolean }) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startLoadingNewOrders());

    if (options?.isCallCenter !== true) {
      await TimeUtils.sleep(500);
      dispatch(slice.actions.getNewOrdersSuccess([]));
      return;
    }

    try {
      const branchId: string = getState().auth.currentBranch?._id;
      const response = await apiInstance.get(`/orders/new`);
      dispatch(slice.actions.getNewOrdersSuccess(response.data.data.orders));
      if (response.data.data.orders.length && options?.withSound) {
        playAudio();
      } else {
        pauseAudio();
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUpcomingOrders() {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const branchId: string = getState().auth.currentBranch?._id;
      const response = await apiInstance.get(`branches/${branchId}/orders/upcoming`);
      dispatch(slice.actions.getUpcomingOrdersSuccess(response.data.data.orders));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCurrentOrders() {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const branchId: string = getState().auth.currentBranch?._id;
      const response = await apiInstance.get(`/orders/current`);
      dispatch(slice.actions.getCurrentOrdersSuccess(response.data.data.orders));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrderDetails(orderId: string) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await apiInstance.get(`orders/${orderId}`);

      if (response.data.status) {
        const currentOrderdetail = response.data.data.order;
        dispatch(slice.actions.currentOrderDetailsSuccess({ currentOrderdetail }));
      } else {
        const error = response.data.message || [...Object.values(response.data?.errors)].join('\n');
        dispatch(slice.actions.hasError({ msg: error }));
      }
    } catch (err: any) {
      const error =
        err.response.data.message || [...Object.values(err.response.data?.errors)].join('\n');
      dispatch(slice.actions.hasError({ msg: error }));
    }
  };
}

export function updateCurrentOrderStatus(orderId: string, key: string, rejectReasonId = '') {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await apiInstance.post(`orders/${orderId}/update-status`, {
        key,
        rejectReasonId
      });

      dispatch(getNewOrders());
      dispatch(getCurrentOrders());
    } catch (err: any) {
      const error =
        err?.response?.data?.message || [...Object.values(err?.response?.data?.errors)].join('\n');
      dispatch(slice.actions.hasError({ msg: error }));
    }
  };
}

export function setSelectedOrder(id: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/sections');
      const currentOrder = getState().orders.ordersList.find((s) => s._id === id);
      dispatch(slice.actions.setSelectedOrderSuccess(currentOrder));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setNewOrders(order: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const nOrders = getState().orders.newOrders;
      const idx = nOrders.findIndex((o) => o._id === order._id);
      if (idx !== -1) {
        nOrders.splice(idx, 1);
      }
      const newOrders = [order, ...nOrders];
      dispatch(slice.actions.getNewOrdersSuccess(newOrders));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateOrderBranch(orderId: string, branchID: string, cb: DoneCallback) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(slice.actions.loadingEditOrderBranch(true));
    try {
      await apiInstance.post(`orders/${orderId}/branch`, { branchID });
      dispatch(getOrderDetails(orderId));
      cb(null);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      cb(error as Error);
    } finally {
      dispatch(slice.actions.loadingEditOrderBranch(false));
    }
  };
}

export function getBrandBranches(brandId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.loadingBrandBranches(true));
    try {
      const response = await apiInstance.get(`brands/${brandId}/branches`);
      dispatch(slice.actions.getBrandBranchesSuccess(response.data.data.branches));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.loadingBrandBranches(false));
    }
  };
}
