import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, IconButton } from '@mui/material';
import { PATH_DASHBOARD } from '../../routes/paths';
import useAuth, { useAuthUser } from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';

export default function AccountPopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const { user, logout }: { user: any; logout: () => void } = useAuthUser();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const MENU_OPTIONS = [
    {
      label: localStorage.getItem('i18nextLng') === 'ar' ? 'الرئيسية' : 'Home',
      icon: homeFill,
      linkTo: '/'
    }
    // {
    //   label: localStorage.getItem('i18nextLng') === 'ar' ? 'الحساب الشخصى' : 'Profile',
    //   icon: personFill,
    //   linkTo: PATH_DASHBOARD.user.profile
    // },
    // {
    //   label: localStorage.getItem('i18nextLng') === 'ar' ? 'الإعدادات' : 'Settings',
    //   icon: settings2Fill,
    //   linkTo: PATH_DASHBOARD.user.account
    // }
  ];

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      if (isMountedRef.current) {
        // [FIX] - check why logout doesn't navigate to login page
        navigate('/', { replace: true });
        handleClose();
        await logout?.();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <>
      {/* <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          border: '1px solid #eee',
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
      </MIconButton> */}

      <IconButton ref={anchorRef} onClick={handleOpen}>
        <Box
          component={Icon}
          icon={settings2Fill}
          sx={{
            // mr: 2,
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#637381'
          }}
        />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {/* {localStorage.getItem('i18nextLng') === 'ar'? user?.brandId?.name.ar : user?.brandId?.name.en } */}
            {user?.user?.firstName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.user?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth variant="contained" onClick={handleLogout}>
            {t('app.logout')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
